import axios from "axios";
import { getBaseUrl } from "./url";

const headers = {
  "Content-Type": "multipart/form-data",
};

const API_URI = getBaseUrl();


const EC2_confidence =
  "https://videoconfidencereg.azurewebsites.net/confidence";

const resumeJSON = "https://new-resume.azurewebsites.net/resume/";

const stage2RoleBased = "https://video-assesment.azurewebsites.net/role_ques/";

const stage2Excel = "https://hiremeclub-test.azurewebsites.net/excel/";

const stage2Analytical =
  "https://hiremeclub-test.azurewebsites.net/analytical/";

const stage2Reasoning = "https://hiremeclub-test.azurewebsites.net/reasoning/";

const stage2CreativeIdea =
  "https://video-assesment.azurewebsites.net/creative/";

const stage2RoleScore = "https://video-assesment.azurewebsites.net/role_score/";

const stage2CreativeScore =
  "https://video-assesment.azurewebsites.net/creative_score/";

const resumeScore = "https://new-resume.azurewebsites.net/score/";

const stage3Fluency = "https://audiofluency.azurewebsites.net/s3";

const stage3Question = "https://video2.hiremeclub.com/situation/";

const aiProctoring = "https://aiproctoringdarshil.azurewebsites.net/proctor/";

const bgRemover = "https://background-removal.azurewebsites.net/changebg/";

//getting the URL for uploading to AWS
export const getSignedUrl = async (data, tok) => {
  try {
    ////console.log(data);
    let fname = {
      name: data,
      token: tok,
    };

    const response = await axios.post(`${API_URI}/image-url`, fname, {
      headers: { "Content-Type": "application/json", withCredentials: true },
    });

    return response.data;
  } catch (error) {
    ////console.log("Error while calling the API ", error.message);
    return error.response;
  }
};

//uploading the video to the URL generated
export const uploadFile = async (url, file) => {
  try {
    const response = await axios.put(url, file, { headers: headers });
    ////console.log("uploaded successfully");
    return response.data;
  } catch (error) {
    ////console.log("Error while calling the API ", error.message);
    return error.response;
  }
};
//for getting the Stage 3 confidence
export const getConfidence = async (token) => {
  try {
    const user = await getUserID(token);
    const response = await axios.post(
      `${EC2_confidence}`,
      {
        file_name: `${user}/stage_3.webm`,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://hiremeclub.com",
        },
      }
    );
    //console.log(response.data);
  } catch (error) {
    //console.log("Error while getting confidence ", error.message);
    return error.response;
  }
};
//for getting the details from the resume in JSON format
export const getResumeJSON = async (tok) => {
  try {
    const user = await getUserID(tok);
    const response = await axios.post(
      resumeJSON,
      {
        file_name: `${user}.pdf`,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://hiremeclub.com",
        },
      }
    );
    // ////console.log("mLApi = ", response.data);
    return response.data;
  } catch (error) {
    // ////console.log("Error while getting resume details ", error.message);
    return error.response;
  }
};
//for getting the stage 2 role based questions
export const getStage2Questions = async (tok, role) => {
  try {
    const user = await getUserID(tok);
    const response = await axios.get(
      `${stage2RoleBased}` + `${user}` + `/` + `${role}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://hiremeclub.com",
        },
      }
    );
    //console.log(response.data);
    return response.data;
  } catch (error) {
    console.log("Error while getting the stage 2 questions ", error.message);
    return error.response;
  }
};

//for getting the stage 2 Excel questions
export const getStage2Excel = async (tok) => {
  try {
    const user = await getUserID(tok);
    const response = await axios.get(`${stage2Excel}` + `${user}`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "https://hiremeclub.com",
      },
    });
    //console.log(response.data);
    return response.data;
  } catch (error) {
    //console.log("Error while getting the stage 2 questions ", error.message);
    return error.response;
  }
};

//for getting the stage 2 Analytical questions
export const getStage2Analytical = async (tok) => {
  try {
    const user = await getUserID(tok);
    const response = await axios.get(`${stage2Analytical}` + `${user}`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "https://hiremeclub.com",
      },
    });
    //console.log(response.data);
    return response.data;
  } catch (error) {
    //console.log("Error while getting the stage 2 questions ", error.message);
    return error.response;
  }
};

//for getting the stage 2 Reasoning questions
export const getStage2Reasoning = async (tok) => {
  try {
    const user = await getUserID(tok);
    const response = await axios.get(`${stage2Reasoning}` + `${user}`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "https://hiremeclub.com",
      },
    });
    //console.log(response.data);
    return response.data;
  } catch (error) {
    //console.log("Error while getting the stage 2 questions ", error.message);
    return error.response;
  }
};

//for getting the stage 2 Reasoning questions
export const getStage2CreativeIdea = async (tok) => {
  try {
    const user = await getUserID(tok);
    const response = await axios.get(`${stage2CreativeIdea}` + `${user}`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "https://hiremeclub.com",
      },
    });
    //console.log(response.data);
    return response.data;
  } catch (error) {
    //console.log("Error while getting the stage 2 questions ", error.message);
    return error.response;
  }
};

//for getting the stage 3 sales pitch
export const getStage3Question = async (token) => {
  try {
    const user = await getUserID(token);
    const response = await axios.get(`${stage3Question}` + `${user}`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "https://hiremeclub.com",
      },
    });
    //console.log(response.data);
    return response.data;
  } catch (error) {
    //console.log("Error while getting question ", error.message);
    return error.response;
  }
};
//to get the fluency for the stage 3 video
export const getStage3Fluency = async (token) => {
  try {
    const user = await getUserID(token);
    const response = await axios.post(`${stage3Fluency}/${user}`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "https://hiremeclub.com",
      },
    });
    ////console.log(response.data.prediction);
    return response.data.prediction;
  } catch (error) {
    ////console.log("Error while getting fluency ", error.message);
    return error.response;
  }
};

//for storing the stage 2 Creative Video score in mongoDB
export const storeStage2CreativeVideoScore = async (tok) => {
  try {
    const user = await getUserID(tok);
    const response = await axios.get(`${stage2CreativeScore}` + `${user}`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "https://hiremeclub.com",
      },
    });
    //console.log(response.data);
    return response.data;
  } catch (error) {
    //console.log("Error while storing score  ", error.message);
    return error.response;
  }
};

//for storing the stage 2 Role based Video score in mongoDB
export const storeStage2RoleVideoScore = async (tok) => {
  try {
    const user = await getUserID(tok);
    const response = await axios.get(`${stage2RoleScore}` + `${user}`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "https://hiremeclub.com",
      },
    });
    //console.log(response.data);
    return response.data;
  } catch (error) {
    //console.log("Error while storing score  ", error.message);
    return error.response;
  }
};
//for getting the resume score from the JSON file
export const getResumeScore = async (data, token) => {
  try {
    const user = await getUserID(token);
    const response = await axios.post(
      `${resumeScore}` + `${user}/`,
      {
        ...data,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://hiremeclub.com",
        },
      }
    );
    //console.log(response.data);
  } catch (error) {
    //console.log("Error while getting resume score ", error.message);
    return error.response;
  }
};
//get the userID from the JWT token
export const getUserID = async (token) => {
  try {
    const response = await axios.post(
      `${API_URI}/UserID`,
      {
        token: token,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://hiremeclub.com",
        },
      }
    );
    ////console.log(response.data.userId);
    return response.data.userId;
  } catch (error) {
    ////console.log(error);
  }
};

//AI proctoring
export const aiProctoringFunc = async (token) => {
  try {
    const user = await getUserID(token);
    const response = await axios.get(`${aiProctoring}` + `${user}/`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "https://hiremeclub.com",
      },
    });
    //console.log(response.data);
  } catch (error) {
    console.log("Error while getting proctoring dATA ", error.message);
    return error.response;
  }
};
//Background remover
export const bgRemoverFunc = async (token) => {
  try {
    const user = await getUserID(token);
    const response = await axios.post(
      `${API_URI}/removeBG`,
      {
        user: user,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://hiremeclub.com",
        },
      }
    );
    //console.log(response.data);
  } catch (error) {
    // console.log("Error while getting proctoring dATA ", error.message);
    return error.response;
  }
};
