import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { MagnifyingGlass } from "react-loader-spinner";
import axios from "axios";
import classes from "./resume.module.css";
import DonutChart from "../components/portal/DonutChart";
import icon from ".././images/icon.png";
import { getBaseUrl } from "../service/url";

const API_URI = getBaseUrl();

function Resume() {
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getDetails(token);
        console.log(response);
        if (response.success) {
          setFormData(response.foundCandidate);
          setLoading(false);
        } else {
          toast.error("User Not Found");
        }
      } catch (error) {
        toast.error("Error fetching data");
      }
    };
    getData();
  }, [token]);

  const getDetails = async (token) => {
    try {
      const response = await axios.post(
        `${API_URI}/candidateDetails`,
        {
          user: token,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "https://hiremeclub.com",
          },
        }
      );
      // console.log(response.data);
      return response.data;
    } catch (error) {
      toast.error("User Not Found");
    }
  };
  // console.log(formData);

  const options = {
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: "20%",
          background: "transparent",
          image: undefined,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
    colors: [
      "#FD0100",
      "#F76915",
      "#b85042",
      "#2FA236",
      "#333ED4",
      "#011936ff",
    ],
    labels: [
      "Resume",
      "Excel Skills",
      "Subject Knowledge",
      "Creativity Score",
      "Analytical Skills",
      "Reasoning Skills",
    ],
    legend: {
      show: true,
      floating: true,
      fontSize: "16px",
      position: "left",
      offsetX: 20,
      offsetY: 0,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0,
      },
      formatter: function (seriesName, opts) {
        return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
      },
      itemMargin: {
        vertical: 3,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  const series = [
    formData.resume_score,
    formData.ExcelScore,
    formData.RoleScore,
    formData.CreativeScore,
    formData.AnalyticalScore,
    formData.ReasoningScore,
    formData.noOfTabSwitches,
  ];

  const [showPDF, setShowPDF] = useState(false);
  const [isLoadingPdf, setIsLoadingPdf] = useState(true);

  const togglePDFView = () => {
    setShowPDF(!showPDF);
    if (!showPDF) setIsLoadingPdf(true); // Reset the loading state when the PDF view is opened
  };

  const handleLoad = () => {
    setIsLoadingPdf(false); // PDF is loaded, hide the loader
  };

  return (
    <div className="h-screen w-full">
      {loading ? (
        <div className="magnifying-glass-wrapper flex flex-col items-center justify-center h-screen">
          <MagnifyingGlass
            visible={true}
            height="120"
            width="120"
            ariaLabel="MagnifyingGlass-loading"
            wrapperStyle={{}}
            wrapperClass="MagnifyingGlass-wrapper"
            glassColor="#c0efff"
            color="#2979FF"
          />
          <h3 className="text-gray-800 font-Kalam">Loading Resume...</h3>
        </div>
      ) : (
        <div className="min-h-screen w-full p-4 print:p-0">
          {/*Resume Content */}
          <div className="h-full w-full bg-white rounded-lg overflow-y-auto">
            {/* Modal content goes here */}
            <div className="h-full px-4">
              <div className={classes.container}>
                <div className={classes.box1}>
                  <div
                    className="bg-gray-700/20 mt-4 w-[200px] h-[200px] md:w-[250px] md:h-[250px] rounded-full p-2 mb-4" /*{classes.section1}*/
                  >
                    <img
                      className="w-full h-full rounded-full object-cover" /*{classes.image}*/
                      src={
                        formData.profile_photo ? formData.profile_photo : icon
                      }
                      alt="Photo"
                    />
                  </div>

                  <div className="mb-4 w-full px-3">
                    <h1 className="font-sans text-2xl font-bold w-full text-left">
                      {formData.name}
                    </h1>
                    <div className="">
                      <table className="table-auto w-full text-left">
                        <tbody>
                          <tr>
                            <td className="text-md font-medium text-black whitespace-nowrap print:text-sm">
                              Email :
                            </td>
                            <td className="px-1 text-black whitespace-pre-wrap print:text-sm">
                              {formData.email || "namanparlecha@gmail.com"}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-md font-medium text-black  whitespace-nowrap print:text-sm">
                              Number :
                            </td>
                            <td className="px-1 text-black print:text-sm">
                              {formData.mobno}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-md font-medium text-black whitespace-nowrap print:text-sm">
                              DOB :
                            </td>
                            <td className="px-1 text-black print:text-sm">
                              {formData.dob}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="justify-center p-8 ">
                    <button
                      className="bg-[#1F509A] text-white font-semibold py-2 px-4 rounded-lg hover:scale-110 transform transition duration-300 ease-in-out "
                      onClick={togglePDFView}>
                      Resume
                    </button>

                    {showPDF && (
                      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">

                        {/* Loading Spinner */}
                        {isLoadingPdf && (
                          <div className="magnifying-glass-wrapper absolute top-1/2 left-1/2 h-screen">
                            <MagnifyingGlass
                              visible={true}
                              height="120"
                              width="120"
                              ariaLabel="MagnifyingGlass-loading"
                              wrapperStyle={{}}
                              wrapperClass="MagnifyingGlass-wrapper"
                              glassColor="#c0efff"
                              color="#2979FF"
                            />
                            <h3 className="text-white font-Kalam">Loading Resume...</h3>
                          </div>
                        )}
                        {/* Close Button */}
                        <button
                          onClick={togglePDFView}
                          className="absolute top-5 right-5 text-white  rounded-full p-2 focus:outline-none">
                          &#10005;
                        </button>

                        {/* PDF Viewer */}
                        <iframe
                          src={`https://docs.google.com/gview?url=${formData.Resume}&embedded=true`}
                          title="PDF Viewer"
                          className="w-screen h-screen p-10 mx-10 border-0 "
                          onLoad={handleLoad}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className={classes.box2}>
                  <h1 className={classes.title}>Educational Details</h1>
                  <hr className={classes.divide} />

                  <div className={`${classes.section} flex gap-2`}>
                    <table className="flex-1 table-auto w-full text-left align-text-top">
                      <tbody className="w-full">
                        <tr className="w-full">
                          <td colSpan={2}>
                            <h3>College Details </h3>
                          </td>
                        </tr>
                        <tr className="w-full">
                          <td className="py-1 font-medium text-black whitespace-nowrap w-1/3 align-text-top">
                            Name :
                          </td>
                          <td className="py-1 text-black w-2/3">
                            {formData.college}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-1/3 h-full py-1 font-medium text-black whitespace-nowrap flex pt-2">
                            Branch :
                          </td>
                          <td className="w-2/3 py-1 text-black">
                            {formData.collegedegree}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-1/3 py-1 font-medium text-black whitespace-nowrap">
                            Year of Passing :
                          </td>
                          <td className="w-2/3 py-1 text-black">
                            {formData.collegeyear}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-1/3 py-1 font-medium text-black whitespace-nowrap">
                            CGPA :
                          </td>
                          <td className="w-2/3 py-1 text-black">
                            {formData.collegecgpa}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="flex-1 table-auto w-full text-left align-text-top">
                      <tbody className="w-full">
                        <tr className="w-full">
                          <td colSpan={2}>
                            <h3>School Details </h3>
                          </td>
                        </tr>
                        <tr>
                          <td className="py-1 font-medium text-black whitespace-nowrap w-1/3 align-text-top">
                            Name :
                          </td>
                          <td className="py-1 text-black w-2/3">
                            {formData.school}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-1/3 h-full py-1 font-medium text-black whitespace-nowrap flex pt-2">
                            Branch :
                          </td>
                          <td className="w-2/3 py-1 text-black">
                            {formData.schooldegree}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-1/3 py-1 font-medium text-black whitespace-nowrap">
                            Year of Passing :
                          </td>
                          <td className="w-2/3 py-1 text-black">
                            {formData.schoolyear}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-1/3 py-1 font-medium text-black whitespace-nowrap">
                            CGPA :
                          </td>
                          <td className="w-2/3 py-1 text-black">
                            {formData.schoolcgpa}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {(formData.exe1 || formData.exe2) && (
                    <>
                      <h1 className={classes.title}>Experience</h1>
                      <hr className={classes.divide} />

                      <div className={classes.section}>
                        {formData.exe1 && (
                          <div className={classes.part1}>
                            <div className={classes.expsection}>
                              <p>Name - {formData.exe1}</p>
                              <p className={classes.exedes1}>
                                {formData.exedes1}
                              </p>
                            </div>
                          </div>
                        )}
                        {formData.exe2 && (
                          <div className={classes.part2}>
                            <div className={classes.expsection}>
                              <p>Name - {formData.exe2}</p>
                              <p className={classes.exedes2}>
                                {formData.exedes1}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  {formData.res && (
                    <>
                      <h1 className={classes.title}>Responsibility</h1>
                      <hr className={classes.divide} />

                      <div className={classes.section}>
                        <div className={classes.part1}>
                          <p className={classes.subheading}>Responsibility 1</p>
                          <p>name - {formData.res}</p>
                          <p className={classes.des}>{formData.resdes}</p>
                        </div>
                      </div>
                    </>
                  )}
                  {(formData.achievement1 || formData.achievement2) && (
                    <>
                      <h1 className={classes.title}>Achivement</h1>
                      <hr className={classes.divide} />
                      <div className={classes.section}>
                        {formData.achievement1 && (
                          <div className={classes.part1}>
                            <p className={classes.subheading}>Achivement 1</p>
                            <p>name - {formData.achievement1}</p>
                            <p className={classes.des}>
                              {formData.achievement1des}
                            </p>
                          </div>
                        )}
                        {formData.achievement2 && (
                          <div className={classes.part2}>
                            <p className={classes.subheading}>Achivement 2</p>
                            <p>name - {formData.achievement2}</p>
                            <p className={classes.des}>
                              {formData.achievement2des}
                            </p>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {formData.certificate && (
                    <>
                      <h1 className={classes.title}>
                        Training & Certification
                      </h1>
                      <hr className={classes.divide} />

                      <div className={classes.section}>
                        <div className={classes.part1}>
                          <p className={classes.subheading}>Certificate 1</p>
                          <p>name - {formData.certificate}</p>
                          <p className={classes.des}>
                            {formData.certificatedes}
                          </p>
                        </div>
                      </div>
                    </>
                  )}

                  {formData.project && (
                    <>
                      <h1 className={classes.title}>Project</h1>
                      <hr className={classes.divide} />
                      <div className={classes.section}>
                        <div className={classes.part1}>
                          <p className={classes.subheading}>Project 1</p>
                          <p>name - {formData.project}</p>
                          <p className={classes.des}>{formData.projectdes}</p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className={`${classes.radialBar} p-4 flex flex-col w-full`}>
                <DonutChart options={options} series={series} />

                <h2 className="w-full text-left text-lg">
                  Candidate Score based on 7 parameters :{" "}
                </h2>
                <table className="w-full bg-white table-auto rounded-lg mb-4">
                  <thead>
                    <tr>
                      <th className="border border-gray-300 p-2">
                        Total Score
                      </th>
                      <th className="border border-gray-300 p-2">Resume</th>
                      <th className="border border-gray-300 p-2">Excel</th>
                      <th className="border border-gray-300 p-2">
                        Subject Knowledge
                      </th>
                      <th className="border border-gray-300 p-2">Creativity</th>
                      <th className="border border-gray-300 p-2">Analytical</th>
                      <th className="border border-gray-300 p-2">Reasoning</th>
                      <th className="border border-gray-300 p-2">
                        Tab Switching Frequency
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {true ? (
                        <>
                          <td className="border border-gray-300 p-2 text-center">
                            {formData.totalScore}
                          </td>
                          <td className="border border-gray-300 p-2 text-center">
                            {formData.resume_score}
                          </td>
                          <td className="border border-gray-300 p-2 text-center">
                            {formData.ExcelScore}
                          </td>
                          <td className="border border-gray-300 p-2 text-center">
                            {formData.RoleScore}
                          </td>
                          <td className="border border-gray-300 p-2 text-center">
                            {formData.CreativeScore}
                          </td>
                          <td className="border border-gray-300 p-2 text-center">
                            {formData.AnalyticalScore}
                          </td>
                          <td className="border border-gray-300 p-2 text-center">
                            {formData.ReasoningScore}
                          </td>
                          <td className="border border-gray-300 p-2 text-center">
                            {formData.noOfTabSwitches}
                          </td>
                        </>
                      ) : (
                        <td
                          className="py-2 px-4 text-center text-yellow-700"
                          colSpan="9"
                        >
                          Pending
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
                <h2 className="w-full text-left">Video Resume : </h2>
                <div className="mb-6">
                  {formData.videoResume ? (
                    <div className="flex justify-center items-center px-4 mb-4">
                      <video
                        controls
                        className="w-full max-w-sm max-h-sm rounded-xl "
                      >
                        <source src={formData.videoResume} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <h2 className="w-full text-left">Role Related Questions : </h2>
                <div>
                  {formData.role_related.ques1 && (
                    <div className="flex justify-center items-center px-4 flex-col h-fit">
                      <h4 className="w-full text-left font-semibold text-xl text-blue-700 mb-2">
                        Question 1 :{" "}
                        <span className="text-lg font-medium text-black">
                          {formData.role_related.ques1}
                        </span>
                      </h4>
                      {formData.roleurl1 ? (
                        <video
                          controls
                          className="w-full max-w-sm max-h-sm rounded-xl "
                        >
                          <source src={formData.roleurl1} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <p className="text-red-500 font-medium">
                          No answer found
                        </p>
                      )}
                    </div>
                  )}
                </div>
                <div>
                  {formData.role_related.ques2 && (
                    <div className="flex justify-center items-center p-4 flex-col h-fit">
                      <h4 className="w-full text-left font-semibold text-xl text-blue-700 mb-2">
                        Question 2 :{" "}
                        <span className="text-lg font-medium text-black">
                          {formData.role_related.ques2}
                        </span>
                      </h4>
                      {formData.roleurl2 ? (
                        <video
                          controls
                          className="w-full max-w-sm max-h-sm rounded-xl "
                        >
                          <source src={formData.roleurl2} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <p className="text-red-500 font-medium">
                          No answer found
                        </p>
                      )}
                    </div>
                  )}
                </div>
                <h2 className="w-full text-left mt-6">
                  Creativity Based Questions :{" "}
                </h2>
                <div>
                  {formData.creative_related.ques1 && (
                    <div className="flex justify-center items-center px-4 flex-col h-fit">
                      <h4 className="w-full text-left font-semibold text-xl text-blue-700 mb-2">
                        Question 1 :{" "}
                        <span className="text-lg font-medium text-black">
                          {formData.creative_related.ques1}
                        </span>
                      </h4>
                      {formData.creativeurl1 ? (
                        <video
                          controls
                          className="w-full max-w-sm max-h-sm rounded-xl "
                        >
                          <source
                            src={formData.creativeurl1}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <p className="text-red-500 font-medium">
                          No answer found
                        </p>
                      )}
                    </div>
                  )}
                </div>
                <div>
                  {formData.creative_related.ques2 && (
                    <div className="flex justify-center items-center p-4 flex-col h-fit">
                      <h4 className="w-full text-left font-semibold text-xl text-blue-700 mb-2">
                        Question 2 :{" "}
                        <span className="text-lg font-medium text-black">
                          {formData.creative_related.ques2}
                        </span>
                      </h4>
                      {formData.creativeurl2 ? (
                        <video
                          controls
                          className="w-full max-w-sm max-h-sm rounded-xl "
                        >
                          <source
                            src={formData.creativeurl2}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <p className="text-red-500 font-medium">
                          No answer found
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Additional instructions */}
          <div className="mt-4">
            <ul className="list-none list-inside">
              <li>
                &#9733; To view scores, please open the link :{" "}
                <a
                  href={`https://hiremeclub.com/resume/${token}`}
                  className="font-semibold italic text-blue-900"
                >
                  https://hiremeclub.com/resume/{token}
                </a>
              </li>
              <li>
                &#9733; This Virtual Resume is created using{" "}
                <a
                  href="https://hiremeclub.com"
                  className="font-semibold italic text-blue-900"
                >
                  https://hiremeclub.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default Resume;
