import { TopNavigation } from "../../components/NavBar/TopNavigation";
import "./dashboard.css";
import { Routes, Route } from "react-router-dom";
import Applicant from "../../components/dashboard/Applicant";
import Sidebar from "../../components/NavBar/CandidateSidebar";
import {
  getCandidateDetails,
  getUserID,
} from "../../service/api";
import { useEffect, useState } from "react";
import Wishlist from "../../components/dashboard/Wishlist";
import ComingSoon from "../../components/ComingSoon";
import Profile from "../../components/dashboard/Profile";
import { Footer } from "../../components/Footer";
import { useNavigate } from "react-router-dom";
import CanInterview from "../../components/dashboard/CanInterview";

const ApplicantDashboard = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);

  useEffect(() => {
    const getDetails = async () => {
      const token = getCookieAndRole();
      const userID = await getUserID(token.token);
      // //console.log("userID", userID);
      if (userID) {
        const data = await getCandidateDetails(userID);
        setUser(data.candidate.profile_photo);
      }
    };

    getDetails();
  }, []);

  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };

  return (
    <div className="bg-gray-100">
      <div className="max-w-full contain bg-gray-100">
        <div className="topNav w-full">
          <TopNavigation
            isSticky={false}
            showAvatar={true}
            showNotification={false}
            showDashboard={true}
            handleLogout={() => navigate("/login")}
            dashboardPath="/dashboard"
            user={user}
          />
        </div>
        <div className="outer-container flex mt-2">
          <div className="sideNav md:grid md:col-start-1 md:row-start-1 z-50 ">
            <Sidebar />
          </div>
          <div className="inner-container rounded-xl row-start-1 col-start-2 lg:col-span-5 md:col-span-5 mr-10 w-full mx-2">
            <Routes>
              <Route path="" element={<Applicant />} />
              <Route path="interview" element={<CanInterview />} />
              <Route path="inbox" element={<ComingSoon />} />
              <Route path="wishlist" element={<Wishlist />} />
              <Route path="profile" element={<Profile />} />
            </Routes>
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <Footer />
      </div>
    </div>
  );
};

export default ApplicantDashboard;
