import React, { useState, useRef, useEffect } from "react";
import {
  uploadFile,
  getSignedUrl,
  getStage2Questions,
  storeStage2RoleVideoScore,
} from "../service/mlAPI";
import { roleLink } from "../service/api";
import problem from "../images/problem.png";
import TabSwitch from "../components/portal/TabSwitch";
import { useNavigate, useLocation } from "react-router-dom";
import { MagnifyingGlass } from "react-loader-spinner";
import TestingNavbar from "../components/NavBar/TestingNavbar";

const mimeType = 'video/webm; codecs="opus,vp8"';

export default function Stage2() {
  const location = useLocation();
  const videoRef = useRef(null);
  const liveVideoFeed = useRef(null);
  const mediaRecorder = useRef(null);
  const [url, setUrl] = useState("");
  const [count, setCount] = useState(0);
  const localVideoChunksRef = useRef([]);
  const permissionRef = useRef(false);
  const recordingStatusRef = useRef("inactive");
  const streamRef = useRef(null);
  const [recordedVideo, setRecordedVideo] = useState(null);
  const [videoChunks, setVideoChunks] = useState([]);
  const [videoBlob, setVideoBlob] = useState(null);
  const timerIdRef = useRef(null);
  const questionNumberRef = useRef(0);
  const questionsArray = useRef([]);
  const randomQuestion = useRef(null);
  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(120);
  const navigate = useNavigate();
  const role1 = useRef("");
  const role2 = useRef("");

  const fetchData = async () => {
    await getCameraPermission();
    ////console.log("after camera permission");
    // Retrieve the state from the previous page
    const state = location.state;
    ////console.log(state);

    // If questionsArray is not present, fetch questions using the API
    if (!state || !state.questionsArray) {
      ////console.log("no state found");
      const token = getCookieAndRole();
      await getQuestionsFromOpenAI(token.token);
    } else {
      ////console.log("State found");
      // Use questionsArray from the previous page
      questionsArray.current = state.questionsArray;
      await startTest();
    }
  };

  useEffect(() => {
    fetchData();
  }, [location]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          // Reset the timer to 0 after 60 seconds
          return 120;
        } else {
          return prevSeconds - 1;
        }
      });
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  const startTimer = () => {
    ////console.log("start Timer function");
    // Clear any existing timer
    if (timerIdRef.current) {
      ////console.log("cleared");
      clearTimeout(timerIdRef.current);
    }

    // Start a new timer and store its ID
    const newTimerId = setTimeout(() => {
      ////console.log("Timer expired!");
      handleNextQuestion();
    }, 120000);

    timerIdRef.current = newTimerId;
  };

  const resetTimer = () => {
    // Clear the timer if it exists
    if (timerIdRef.current) {
      ////console.log("Reset Timer is Called");
      clearTimeout(timerIdRef.current);
      timerIdRef.current = null; // Reset the timer ID
    }
  };

  //when we get video then upload it to aws
  const AWSUpload = async (Blob, url) => {
    ////console.log("upload function start", Blob.type);
    if (Blob) {
      await uploadFile(url, Blob);
      setUrl(url.split("?")[0]);
    }
  };
  const getNextQuestion = async () => {
    if (questionsArray.current.length === 0) {
      ////console.log("No questions available.");
      return;
    }
    setSeconds(120);
    //console.log(questionsArray);
    // Get and remove the first question from the array
    const randomQues = questionsArray.current.shift();
    randomQuestion.current = randomQues;
    questionNumberRef.current += 1;
    await startRecording();
  };

  const handleNextQuestion = async () => {
    if (questionNumberRef.current >= 2) {
      endRecording();
    } else {
      stopRecording();
    }
  };
  const getQuestionsFromOpenAI = async (data) => {
    try {
      let q5 = await getStage2Questions(data);
      questionsArray.current = [q5.ques1, q5.ques2];
      startTest();
      ////console.log(questionsArray.current);
    } catch (error) {
      ////console.log("Error getting questions from OpenAI", error.message);
    }
  };

  const getCameraPermission = async () => {
    try {
      const videoConstraints = {
        audio: false,
        video: true,
      };

      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const videoStream = await navigator.mediaDevices.getUserMedia(
        videoConstraints
      );

      const combinedStream = new MediaStream([
        ...videoStream.getVideoTracks(),
        ...audioStream.getAudioTracks(),
      ]);

      streamRef.current = combinedStream;
      permissionRef.current = true;
      liveVideoFeed.current.srcObject = videoStream;
    } catch (err) {
      console.error("Error accessing camera:", err.message);
      alert("Error accessing camera: " + err.message);
    }
  };

  const startRecording = async () => {
    resetTimer();
    recordingStatusRef.current = "recording";

    const media = new MediaRecorder(streamRef.current, { mimeType });
    mediaRecorder.current = media;
    mediaRecorder.current.start();

    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localVideoChunksRef.current = [];
      localVideoChunksRef.current.push(event.data);
    };

    setVideoChunks(localVideoChunksRef.current);
    startTimer();
  };

  const startTest = async () => {
    ////console.log("started testing in start test function");
    recordingStatusRef.current = "recording";

    // Ensure that the stream is not null before creating MediaRecorder
    if (streamRef.current) {
      const media = new MediaRecorder(streamRef.current, { mimeType });
      mediaRecorder.current = media;
      mediaRecorder.current.start();

      mediaRecorder.current.ondataavailable = (event) => {
        if (typeof event.data === "undefined") return;
        if (event.data.size === 0) return;
        localVideoChunksRef.current = [];
        localVideoChunksRef.current.push(event.data);
      };

      setVideoChunks(localVideoChunksRef.current);
      if (questionsArray.current.length === 0) {
        ////console.log("No more questions available.");
        return;
      }
      ////console.log(questionsArray);
      // Get and remove the first question from the array
      const randomQues = questionsArray.current.shift();
      randomQuestion.current = randomQues;
      questionNumberRef.current += 1;
      ////console.log("Stage_2_", questionNumberRef.current);
      startTimer();
    } else {
      console.error("Stream is null. Cannot start recording.");
    }
  };

  const stopRecording = () => {
    // permissionRef.current = false;
    // recordingStatusRef.current = "inactive";
    mediaRecorder.current.stop();

    mediaRecorder.current.onstop = async () => {
      const vidBlob = new Blob(localVideoChunksRef.current, {
        type: mimeType,
      });
      const videoUrl = URL.createObjectURL(vidBlob);
      setRecordedVideo(videoUrl);
      setVideoBlob(vidBlob);
      setVideoChunks([]);
      let videoNum = "role-video" + questionNumberRef.current;
      let token = getCookieAndRole();
      const response = await getSignedUrl(videoNum, token.token);
      role1.current = response.url;
      await getNextQuestion();
      await AWSUpload(vidBlob, response.url);
    };
  };

  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };

  const endRecording = () => {
    setLoading(true);
    permissionRef.current = false;
    recordingStatusRef.current = "inactive";
    mediaRecorder.current.stop();
    resetTimer();
    mediaRecorder.current.onstop = async () => {
      const vidBlob = new Blob(localVideoChunksRef.current, {
        type: mimeType,
      });
      const videoUrl = URL.createObjectURL(vidBlob);
      setRecordedVideo(videoUrl);
      setVideoBlob(vidBlob);
      setVideoChunks([]);
      let videoNum = "role-video" + questionNumberRef.current;
      const response = await getSignedUrl(videoNum);
      if (questionNumberRef.current == 2) {
        role2.current = response.url;
      } else {
        role1.current = response.url;
      }
      await AWSUpload(vidBlob, response.url);
      let token = getCookieAndRole();
      roleLink(token.token, role1, role2);
      storeStage2RoleVideoScore(token.token);
      navigate("/instruction-mcq");
      setLoading(false);
    };
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };
  const isBlinking = seconds <= 10 && seconds % 2 === 0;

  return (
    <div>
      {loading ? (
        <div className="magnifying-glass-wrapper flex flex-col items-center justify-center h-screen">
          <MagnifyingGlass
            visible={true}
            height="160"
            width="160"
            ariaLabel="MagnifyingGlass-loading"
            wrapperStyle={{}}
            wrapperClass="MagnifyingGlass-wrapper"
            glassColor="#c0efff"
            color="#2979FF"
          />
        </div>
      ) : (
        <div className="bg-gray-50">
          <TestingNavbar
            showAvatar={true}
            handleLogout={() => navigate("/login")}
          />
          <TabSwitch />
          <div className="flex flex-col items-center justify-center bg-gray-50 min-h-fit min-w-full">
            <img
              src={problem}
              alt="Question vector Image"
              className="max-w-min max-h-min ml-8 mt-2"
            />
            {/* <CountdownTimer initialTime={seconds} className="max-h-fit" /> */}

            <div
              className={`p-2 border-4 rounded-lg text-center ${
                seconds <= 10
                  ? isBlinking
                    ? "border-red-500 animate-ping"
                    : "border-red-500"
                  : "border-black"
              }`}
            >
              <div className="text-4xl font-bold text-gray-800">
                {formatTime(seconds)}
              </div>
            </div>

            <div></div>

            <div className="bg-white rounded-3xl shadow-2xl p-4 m-10 mt-2">
              {/* <p>{seconds}</p> */}
              <h3 className="text-black p-2 m-2">
                {randomQuestion ? (
                  <div>
                    <p>{randomQuestion.current}</p>
                  </div>
                ) : (
                  <p>Loading random question...</p>
                )}
              </h3>
            </div>
            <div className="flex justify-center">
              <video
                className="w-72 h-48 live-player sm:w-3/5 sm:h-auto md:aspect-w-16/9 sm:aspect-h-9/16 bg-gray-500"
                ref={liveVideoFeed}
                autoPlay
              ></video>
            </div>

            <div className="mt-4">
              {recordingStatusRef.current === "recording" && (
                <>
                  <button
                    onClick={handleNextQuestion}
                    className={`${
                      questionNumberRef.current === 2 ? "hidden" : ""
                    } bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2`}
                  >
                    Next Question
                  </button>
                  <button
                    onClick={endRecording}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  >
                    End Test
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
