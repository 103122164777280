import { useState, useEffect, useRef } from "react";
import {
  ArrowLeftOnRectangleIcon,
  PresentationChartLineIcon,
} from "@heroicons/react/24/outline";
import icon from "../../images/icon.png";
import favicon from "../../images/favicon.png";
import { Link, useNavigate } from "react-router-dom";
import "../../components/customCss/topNavigation.css";
import { toast } from "react-toastify";
import NotificationComponent from "../dashboard/Notification";
export const TopNavigation = ({
  isSticky,
  showDashboard,
  showNotification,
  showAvatar,
  handleLogout,
  dashboardPath,
  user,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const handleAvatarClick = () => {
    // Only open the menu if the avatar is shown
    if (showAvatar) {
      setIsMenuOpen(!isMenuOpen);
    }
  };

  const handleDashboard = () => {
    setIsMenuOpen(false);
    navigate(dashboardPath);
  };

  const handleLogoutClick = async () => {
    setIsMenuOpen(false);
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    await handleLogout();

    toast.error("Logout Successfully!");
  };

  const closeMenuOnOutsideClick = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", closeMenuOnOutsideClick);

    return () => {
      window.removeEventListener("mousedown", closeMenuOnOutsideClick);
    };
  }, []);

  const navbarStyle = isSticky ? "sticky top-0 left-0 right-0 z-10 p-2" : "relative";

  return (
    <div
      className={`py-5 z-10 px-4 pt-8 lg:px-24 lg:py-4 bg-slate-100 shadow-lg ${navbarStyle}`}
    >
      <div className="relative navigation flex items-center justify-between">
        <Link
          to="/"
          aria-label="HiremeClub Logo"
          title="HiremeClub Logo"
          className="inline-flex items-center"
        >
          <img src={favicon} alt="Logo" className="h-16 w-16 mr-0" />
          <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 font-custom">
            Hireme<span className="text-blue-500">Club</span>
          </span>
        </Link>

        {showAvatar && (
          <div className="flex icon  items-center md:space-x-8 lg:flex">
            {showNotification && <NotificationComponent />}{" "}
            <button
              className="flex items-center focus:outline-none"
              onClick={handleAvatarClick}
            >
              <img
                className="h-12 w-12 rounded-full object-cover hover:shadow-sm"
                src={user ? user : icon}
                alt="Avatar"
              />
            </button>
            {isMenuOpen && (
              <div
                ref={menuRef}
                className="absolute right-0 mt-36 w-36 bg-white border rounded-lg shadow-md drop-shadow-2xl"
              >
                {showDashboard && (
                  <div className="block text-bottom px-4 py-2 hover:bg-gray-300">
                    <button onClick={handleDashboard}>
                      <PresentationChartLineIcon className="h-6 w-6 mr-2 inline" />
                      Dashboard
                    </button>
                  </div>
                )}
                <div className="block text-bottom px-4 py-2 rounded-b-lg hover:bg-red-600">
                  <button onClick={handleLogoutClick}>
                    <ArrowLeftOnRectangleIcon className="w-6 h-6 inline-block mr-2" />
                    Logout
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TopNavigation;
