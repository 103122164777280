import React, { useState } from "react";
import TestingNavbar from "../components/NavBar/TestingNavbar";
import { getUserID } from "../service/mlAPI";
import { feedbackSubmit } from "../service/api";
import { useNavigate } from "react-router-dom";
const Feedback = () => {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");
  const navigate = useNavigate();

  const handleRatingChange = (e) => {
    setRating(parseInt(e.target.value));
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let token = getCookieAndRole();
    let user = await getUserID(token.token);
    await feedbackSubmit(user, rating, feedback);
    // You can perform further actions here like sending the feedback data to a server
    // console.log("Rating:", rating);
    // console.log("Feedback:", feedback);
    // Reset the form after submission
    navigate("/dashboard");
    setRating(0);
    setFeedback("");
  };

  return (
    <div className="bg-gray-50 h-screen">
      <TestingNavbar />
      <div className="max-w-md mx-auto mt-10 ">
        <div className="text-4xl heading text-gray-700 mb-8 font-semibold">
          Help us to <span className="text-blue-600">Improve</span>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="rating"
              className="block text-gray-700 font-bold mb-2"
            >
              Rate your experience:
            </label>
            <select
              id="rating"
              value={rating}
              onChange={handleRatingChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
            >
              <option value={0}>Select...</option>
              <option value={1}>1 - Poor</option>
              <option value={2}>2 - Fair</option>
              <option value={3}>3 - Good</option>
              <option value={4}>4 - Very Good</option>
              <option value={5}>5 - Excellent</option>
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="feedback"
              className="block text-gray-700 font-bold mb-2"
            >
              Your Feedback:
            </label>
            <textarea
              id="feedback"
              value={feedback}
              onChange={handleFeedbackChange}
              rows={4}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
            ></textarea>
          </div>

          <div className="flex flex-row justify-between">
            {/* skip button */}

            <button>
              <a
                href="/dashboard"
                className="bg-gray-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring focus:border-blue-300"
              >
                Skip
              </a>
            </button>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring focus:border-blue-300"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Feedback;
