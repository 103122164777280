import React from 'react';
import Coming from "../images/Coming.png"
const ComingSoon = () => {
  return (
    <div className="flex justify-center items-center h-fit bg-gray-100">
      <img src={Coming} alt="coming" className="w-2/3 h-2/3" />
    </div>
  );
};

export default ComingSoon;