import React, { useEffect, useRef, useState } from "react";
import { Footer } from "../components/Footer";
import { Transition } from "@headlessui/react";
import { getResumeJSON, getResumeScore } from "../service/mlAPI";
import { getUserID } from "../service/mlAPI";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { MagnifyingGlass } from "react-loader-spinner";
import TestingNavbar from "../components/NavBar/TestingNavbar";
import { uploadFile } from "../service/mlAPI";
import { getSignedUserProfilePhotoUrl, resumeLink } from "../service/api";
import { getBaseUrl } from "../service/url";

export default function ResumeData() {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [url, setUrl] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  let test;
  const [file, setFile] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateScore, setNavigateScore] = useState(false);
  const location = useLocation();
  const [error, setError] = useState("");
  const { isUploaded } = location.state || {};
  const { newurl } = location.state || "";
  const navigate = useNavigate();
  const [resumeDataLoaded, setResumeDataLoaded] = useState(false);
  const API_URI = getBaseUrl();
  const [formData, setFormData] = useState({
    name: "",
    dob: "",
    mobno: "",
    profile_photo: "",
    college: "",
    collegedegree: "",
    collegeyear: "",
    collegecgpa: "",
    school: "",
    schooldegree: "",
    schoolyear: "",
    schoolcgpa: "",
    exe1: "",
    exedes1: "",
    exe2: "",
    exedes2: "",
    res: "",
    resdes: "",
    achievement1: "",
    achievement1des: "",
    achievement2: "",
    achievement2des: "",
    certificate: "",
    certificatedes: "",
    project: "",
    projectdes: "",
    skills: [],
  });
  const [resumeData, setResumeData] = useState({
    achievements: [],
    educational_details: {
      cgpa: 0,
      college_details: [],
      degrees: [],
    },
    name: "",
    projects: {
      description: [],
      project_names: [],
    },
    responsibilities: {
      description: [],
      positions_of_responsibility: [],
    },
    skills: [],
    "trainings and certifications": [],
    work_experience: {
      description: [],
      experience: [],
    },
  });
  const resume = useRef(null);
  const skillsData = [
    "Business Development",
    "Sales",
    "Marketing",
    "Customer Success",
    "English",
    "MS Excel",
    "Digital Marketing",
    "Email Marketing",
    "MS Office",
    "Social Media Marketing",
    "Google Analytics Tools",
    "SEO",
    "Facebook Marketing",
    "Instagram Marketing",
    "Communication",
    "CRM",
  ];

  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };

  //to send the resume details to the backend
  const sendResumeDetails = async () => {
    try {
      let token = getCookieAndRole();
      let user = await getUserID(token.token);
      //console.log(formData);
      // //console.log(url);
      // //console.log(user);
      //console.log(profileUrl);
      //console.log(test);
      const response = await axios.post(
        `${API_URI}/Candidate/new`,
        {
          ...formData,
          isScoreCalculated: false,
          user: user,
          profile_photo: test,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "https://hiremeclub.com",
          },
        }
      );

      return response.data;
    } catch (error) {
      ////console.log(error.response.data);
      console.error("Error sending resume details:", error);
      const errorMessage = error.response?.data?.message || "An error occurred. Please try again.";
      toast.error(errorMessage);
    }
  };

  const getResume = async () => {
    try {
      let token = getCookieAndRole();
      resume.current = await getResumeJSON(token.token);
      let college = resume.current.educational_details.college_details[0];
      let name = resume.current.name;
      let collegedegree = resume.current.educational_details.degrees[0];
      let school = resume.current.educational_details.college_details[1];
      let schooldegree = resume.current.educational_details.degrees[1];
      let exe1 = resume.current.work_experience.experience[0];
      let exedes1 = resume.current.work_experience.description[0];
      let exe2 = resume.current.work_experience.experience[1];
      let exedes2 = resume.current.work_experience.description[1];
      let res = resume.current.responsibilities.positions_of_responsibility[0];
      let resdes = resume.current.responsibilities.description[0];
      let project = resume.current.projects.project_names[0];
      let projectdes = resume.current.projects.description[0];
      // collegeName.current= college;
      // ////console.log(collegeName.current);
      setFormData({
        name: name,
        college: college,
        collegedegree: collegedegree,
        school: school,
        schooldegree: schooldegree,
        exe1: exe1,
        exedes1: exedes1,
        exe2: exe2,
        exedes2: exedes2,
        res: res,
        resdes: resdes,
        project: project,
        projectdes: projectdes,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setResumeDataLoaded(true);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        let token = getCookieAndRole();
        const response = await getSignedUserProfilePhotoUrl(token.token);
        setUrl(response.url.split("?")[0]);
      } catch (error) {
        console.error("Error getting profile photo:", error);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    if (isUploaded === true) {
      setLoading(true);
      // //console.log(loading);
      getResume();
    }
  }, [isUploaded]);

  const submitFormData = async (e) => {
    e.preventDefault();
    if (!file) {
      const firstLetter = formData.name.charAt(0).toUpperCase();
      //console.log(firstLetter);
      const imageUrl = `https://dummyimage.com/200x200/3b82f6/ffffff&text=${firstLetter}`;

      setFile(imageUrl);
      setFormData((prevFormData) => ({
        ...prevFormData,
        profile_photo: imageUrl,
      }));
      setProfileUrl(imageUrl);
      test = imageUrl;
      // //console.log("run if");
      //console.log(formData);
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        profile_photo: url,
      }));
      setProfileUrl(url);
      test = url;
      // //console.log("run else");
      await uploadFile(url, file);
    }
    //console.log(formData);
    // //console.log("from submit handler", url);

    // //console.log(formData);
    const response = await sendResumeDetails();
    if (response.success) {
      toast.success("Resume details submitted successfully");
      setNavigateScore(true);
    }
    // Update resumeData state based on formData
    setResumeData({
      achievements: [
        `${formData.achievement1} ${formData.achievement1des}`,
        `${formData.achievement2} ${formData.achievement2des}`,
        // Add more achievements as needed
      ],
      educational_details: {
        cgpa: parseFloat(formData.collegecgpa) || 0,
        college_details: [formData.college, formData.school],
        degrees: [formData.collegedegree, formData.schooldegree],
      },
      name: formData.name,
      projects: {
        description: [formData.projectdes],
        project_names: [formData.project],
      },
      responsibilities: {
        description: [formData.resdes],
        positions_of_responsibility: [formData.res],
      },
      skills: formData.skills,
      "trainings and certifications": [
        `${formData.certificate} ${formData.certificatedes}`,
        // Add more certifications as needed
      ],
      work_experience: {
        description: [formData.exedes1, formData.exedes2],
        experience: [formData.exe1, formData.exe2],
      },
    });
    setResumeDataLoaded(true);
  };

  useEffect(() => {
    const performActions = async () => {
      if (Object.keys(resumeData).length > 0 && resumeDataLoaded) {
        let token = getCookieAndRole();
        // console.log("New url", newurl);
        await resumeLink(token.token, newurl);
        getResumeScore(resumeData, token.token);
        if (navigateScore) {
          navigate("/instruction");
        }
      }
    };

    performActions();
  }, [resumeData, resumeDataLoaded]);

  const handleSkillChange = (skill) => {
    setSelectedSkills((prevSkills) =>
      prevSkills.includes(skill)
        ? prevSkills.filter((s) => s !== skill)
        : [...prevSkills, skill]
    );
  };

  // Handle form data changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the input name is 'mobno' (mobile number)
    if (name === "mobno") {
      // Check if the entered value is not a 10-digit number
      if (!/^\d{10}$/.test(value)) {
        setError("Please enter a 10-digit number");
      } else {
        setError(""); // Clear the error if the input is valid
      }
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value, // Update the specific field in formData
    }));
  };

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      skills: selectedSkills,
    }));
  }, [selectedSkills]);

  return (
    <div>
      {!resumeDataLoaded && loading ? (
        <div className="magnifying-glass-wrapper flex flex-col items-center justify-center h-screen">
          <MagnifyingGlass
            visible={true}
            height="160"
            width="160"
            ariaLabel="MagnifyingGlass-loading"
            wrapperStyle={{}}
            wrapperClass="MagnifyingGlass-wrapper"
            glassColor="#c0efff"
            color="#2979FF"
          />
          <h3 className="text-gray-800 font-Kalam">
            Extracting Data from Resume
          </h3>
        </div>
      ) : (
        <div className="bg-gray-50">
          <TestingNavbar
            isSticky={false}
            showAvatar={true}
            handleLogout={() => navigate("/login")}
          />
          <div className="resume-data">
            <div className="bg-gray-50 min-h-screen py-8 lg:px-36">
              <div className="container mx-auto sm:p-8">
                <h2 className="text-3xl font-semibold mb-4 text-center">
                  Candidate's Detail
                </h2>
                <div className="border bg-white rounded-lg lg:p-5 sm:p-8">
                  <p className="italic text-red-600 text-xs">* Required</p>

                  {/*Basic Candidate's Details */}
                  <div className="mb-6 flex space-x-4">
                    <div className="w-full">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium "
                      >
                        Name <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2 "
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="w-full">
                      <label
                        htmlFor="dob"
                        className="block mb-2 text-sm font-medium "
                      >
                        Date of Birth <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="date"
                        name="dob"
                        className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  {/*Contact Information */}
                  <div className="mb-6 flex space-x-4">
                    <div className="w-full">
                      <label
                        htmlFor="phone"
                        className="block mb-2 text-sm font-medium"
                      >
                        Mobile Number <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="tel"
                        name="mobno"
                        className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                        onChange={handleInputChange}
                      />
                      {error && <p className="text-red-600">{error}</p>}
                    </div>

                    <div className="w-full">
                      <label
                        htmlFor="photo"
                        className="block mb-2 text-sm font-medium "
                      >
                        Profile Photo
                      </label>
                      {formData.profile_photo && (
                        <img
                          src={url}
                          alt="profile"
                          className="w-20 h-20 rounded-full"
                          onChange={(e) => setFile(e.target.files[0])}
                        />
                      )}{" "}
                      <input
                        type="file"
                        className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                        id="profileImage"
                        name="profileImage"
                        accept="image/*"
                        onChange={(e) => setFile(e.target.files[0])}
                      ></input>
                    </div>
                  </div>
                </div>

                {/*Education Details */}

                <div className="mb-6">
                  <h3 className="text-lg font-semibold mt-5 mb-2">
                    Educational Details
                  </h3>

                  <div className="mb-4 border bg-white rounded-lg lg:p-5 sm:p-2">
                    <h4 className="text-md font-semibold mb-2">College</h4>
                    <div className="space-y-2">
                      <div className="flex space-x-4">
                        <div className="w-full">
                          <label
                            htmlFor="collegeName"
                            className="block mb-2 text-sm font-medium"
                          >
                            Name <span className="text-red-600">*</span>
                          </label>
                          <input
                            type="text"
                            name="college"
                            value={formData.college}
                            onChange={handleInputChange}
                            className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                          />
                        </div>
                        <div className="w-full">
                          <label
                            htmlFor="degree"
                            placeholder="Degree"
                            className="block mb-2 text-sm font-medium"
                          >
                            Degree <span className="text-red-600">*</span>
                          </label>
                          <input
                            type="text"
                            name="collegedegree"
                            className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                            value={formData.collegedegree}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="flex space-x-4">
                        <div className="w-full">
                          <label
                            htmlFor="dob"
                            className="block mb-2 text-sm font-medium "
                          >
                            Year of Passing{" "}
                            <span className="text-red-600">*</span>
                          </label>
                          <input
                            type="number"
                            name="collegeyear"
                            min="1900"
                            max="2099"
                            step="1"
                            placeholder="Enter a year"
                            className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="w-full">
                          <label
                            htmlFor="cgpa"
                            className="block mb-2 text-sm font-medium"
                          >
                            CGPA{" "}
                            <span className="text-red-600">
                              * (Please do not enter % symbol)
                            </span>
                          </label>
                          <input
                            type="text"
                            name="collegecgpa"
                            className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-4 border bg-white rounded-lg lg:p-5 sm:p-2">
                    <h4 className="text-md font-semibold mb-2">School</h4>
                    <div className="space-y-2">
                      <div className="flex space-x-4">
                        <div className="w-full">
                          <label
                            htmlFor="schoolName"
                            className="block mb-2 text-sm font-medium"
                          >
                            Name <span className="text-red-600">*</span>
                          </label>
                          <input
                            type="text"
                            name="school"
                            className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                            value={formData.school}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="w-full">
                          <label
                            htmlFor="degree"
                            className="block mb-2 text-sm font-medium"
                          >
                            Degree <span className="text-red-600">*</span>
                          </label>
                          <input
                            type="text"
                            name="schooldegree"
                            className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                            value={formData.schooldegree}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="flex space-x-4">
                        <div className="w-full">
                          <label
                            htmlFor="dob"
                            className="block mb-2 text-sm font-medium "
                          >
                            Year of Passing{" "}
                            <span className="text-red-600">*</span>
                          </label>
                          <input
                            type="number"
                            name="schoolyear"
                            min="1900"
                            max="2099"
                            step="1"
                            placeholder="Enter a year"
                            className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="w-full">
                          <label
                            htmlFor="cgpa"
                            className="block mb-2 text-sm font-medium"
                          >
                            CGPA{" "}
                            <span className="text-red-600">
                              * (Please do not enter % symbol)
                            </span>
                          </label>
                          <input
                            type="text"
                            name="schoolcgpa"
                            className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*Skills and Experience Details */}
                <div className="mb-6 ">
                  <h3 className="text-lg font-semibold mb-2">Experience</h3>

                  <div className="mb-4 border bg-white rounded-lg lg:p-5 sm:p-2">
                    <div className="space-y-2">
                      <h4 className="text-md font-semibold mb-2">
                        Experience 1
                      </h4>
                      <div className="flex space-x-4">
                        <div className="w-full">
                          <label
                            htmlFor="organizationName"
                            className="block mb-2 text-sm font-medium"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            name="exe1"
                            className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                            value={formData.exe1}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="flex space-x-4">
                        <div className="w-full">
                          <label
                            htmlFor="desciption"
                            className="block mb-2 text-sm font-medium"
                          >
                            Description
                          </label>
                          <textarea
                            type="textarea"
                            name="exedes1"
                            rows={2}
                            cols={20}
                            className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                            value={formData.exedes1}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="space-y-2">
                      <h4 className="text-md font-semibold mb-2">
                        Experience 2
                      </h4>
                      <div className="flex space-x-4">
                        <div className="w-full">
                          <label
                            htmlFor="organizationName"
                            className="block mb-2 text-sm font-medium"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            name="exe2"
                            className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                            value={formData.exe2}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="flex space-x-4">
                        <div className="w-full">
                          <label
                            htmlFor="desciption"
                            className="block mb-2 text-sm font-medium"
                          >
                            Description
                          </label>
                          <textarea
                            type="textarea"
                            name="exedes2"
                            rows={2}
                            cols={20}
                            className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                            value={formData.exedes2}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/** Experience Section Ends Here **/}

                  {/** Skills Section starts Here **/}
                  <div className="mb-6 mt-5">
                    <h3 className="text-lg font-semibold mb-2">Skills</h3>
                    <div className="relative">
                      <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="border border-gray-300 px-4 py-2 w-full text-left rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      >
                        Choose Your Skills{" "}
                        <span className="text-red-600">*</span>
                      </button>
                      <Transition
                        show={isOpen}
                        enter="transition ease-out duration-100"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <div className="absolute z-10 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                          <div className="py-1 grid grid-cols-2 md:grid-cols-4 gap-2">
                            {skillsData.map((skill) => (
                              <label
                                key={skill}
                                className="flex items-center px-4 py-2 hover:bg-gray-100"
                              >
                                <input
                                  type="checkbox"
                                  value={skill}
                                  checked={selectedSkills.includes(skill)}
                                  onChange={() => handleSkillChange(skill)}
                                  className="mr-2"
                                />
                                {skill}
                              </label>
                            ))}
                          </div>
                        </div>
                      </Transition>
                    </div>
                  </div>
                </div>

                {/*Responsibilities Details */}
                <div className="mb-6">
                  <h3 className="text-lg font-semibold mb-2 mt-5">
                    Responsibility
                  </h3>
                  <div className="border bg-white rounded-lg lg:p-5 sm:p-2">
                    <div className="mb-4 ">
                      <div className="space-y-2">
                        <div className="flex space-x-4">
                          <div className="w-full">
                            <label
                              htmlFor="organizationName"
                              className="block mb-2 text-sm font-medium"
                            >
                              Name
                            </label>
                            <input
                              type="text"
                              name="res"
                              className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2 "
                              value={formData.res}
                              onChange={handleInputChange}
                            />
                          </div>

                          <div className="w-full">
                            <label
                              htmlFor="desciption"
                              className="block mb-2 text-sm font-medium"
                            >
                              Description
                            </label>
                            <textarea
                              type="textarea"
                              name="resdes"
                              rows={2}
                              cols={20}
                              className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2 "
                              value={formData.resdes}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*Achievement Details */}
                <div className="mb-6">
                  <h3 className="text-lg font-semibold mb-2">Achievements</h3>
                  <div className="border bg-white rounded-lg lg:p-5 sm:p-2">
                    <div className="mb-4">
                      <h4 className="text-md font-semibold mb-2">
                        Achievement 1
                      </h4>
                      <div className="space-y-2">
                        <div className="flex space-x-4">
                          <div className="w-full">
                            <label
                              htmlFor="organizationName"
                              className="block mb-2 text-sm font-medium"
                            >
                              Name
                            </label>
                            <input
                              type="text"
                              name="achievement1"
                              className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2 "
                              onChange={handleInputChange}
                            />
                          </div>

                          <div className="w-full">
                            <label
                              htmlFor="desciption"
                              className="block mb-2 text-sm font-medium"
                            >
                              Description
                            </label>
                            <textarea
                              type="textarea"
                              name="achievement1des"
                              rows={2}
                              cols={10}
                              className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2 "
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <h4 className="text-md font-semibold mb-2">
                        Achievement 2
                      </h4>
                      <div className="space-y-2">
                        <div className="flex space-x-4">
                          <div className="w-full">
                            <label
                              htmlFor="organizationName"
                              className="block mb-2 text-sm font-medium"
                            >
                              Name
                            </label>
                            <input
                              type="text"
                              name="achievement2"
                              className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2 "
                              onChange={handleInputChange}
                            />
                          </div>

                          <div className="w-full">
                            <label
                              htmlFor="desciption"
                              className="block mb-2 text-sm font-medium"
                            >
                              Description
                            </label>
                            <textarea
                              type="textarea"
                              name="achievement2des"
                              rows={2}
                              cols={20}
                              className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2 "
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*Training and Certifications Details */}
                <div className="mb-6">
                  <h3 className="text-lg font-semibold mb-2">
                    Training and Certifications
                  </h3>
                  <div className="border bg-white rounded-lg lg:p-5 sm:p-2">
                    <div className="mb-4">
                      <h4 className="text-md font-semibold mb-2">
                        Certificate
                      </h4>
                      <div className="space-y-2">
                        <div className="flex space-x-4">
                          <div className="w-full">
                            <label
                              htmlFor="organizationName"
                              className="block mb-2 text-sm font-medium"
                            >
                              Name
                            </label>
                            <input
                              type="text"
                              name="certificate"
                              className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2 "
                              onChange={handleInputChange}
                            />
                          </div>

                          <div className="w-full">
                            <label
                              htmlFor="desciption"
                              className="block mb-2 text-sm font-medium"
                            >
                              Description
                            </label>
                            <textarea
                              type="textarea"
                              name="certificatedes"
                              rows={2}
                              cols={20}
                              className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2 "
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*Projects Details */}
                <div className="mb-6">
                  <h3 className="text-lg font-semibold mb-2">Projects</h3>
                  <div className="border bg-white rounded-lg lg:p-5 sm:p-2">
                    <div className="mb-4">
                      <div className="space-y-2">
                        <div className="flex space-x-4">
                          <div className="w-full">
                            <label
                              htmlFor="organizationName"
                              className="block mb-2 text-sm font-medium"
                            >
                              Name
                            </label>
                            <input
                              type="text"
                              name="project"
                              className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2 "
                              value={formData.project}
                              onChange={handleInputChange}
                            />
                          </div>

                          <div className="w-full">
                            <label
                              htmlFor="desciption"
                              className="block mb-2 text-sm font-medium"
                            >
                              Description
                            </label>
                            <textarea
                              type="textarea"
                              name="projectdes"
                              rows={2}
                              cols={20}
                              className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2 "
                              value={formData.projectdes}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*Submit Button */}

                <div className="mb-6 flex justify-center">
                  <button
                    type="submit"
                    onClick={submitFormData}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}
