import React, { useEffect, useState } from "react";
import classes from "./modal.module.css";
import icon from "../../images/icon.png";
import DonutChart from "../portal/DonutChart";
import { Link } from "react-router-dom";

const Modal = ({ isOpen, closeModal, candidateRef }) => {
  const [isSkills, setIsSkills] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    dob: "",
    mobno: "",
    email: "",
    profile_photo: "",
    videoResume: "",
    college: "",
    collegedegree: "",
    collegeyear: "",
    collegecgpa: "",
    school: "",
    schooldegree: "",
    schoolyear: "",
    schoolcgpa: "",
    exe1: "",
    exedes1: "",
    exe2: "",
    exedes2: "",
    res: "",
    resdes: "",
    achievement1: "",
    achievement1des: "",
    achievement2: "",
    achievement2des: "",
    certificate: "",
    certificatedes: "",
    project: "",
    projectdes: "",
    skills: [],
    roleQues1: "",
    roleQues2: "",
    creativityQues1: "",
    creativityQues2: "",
    roleurl1: "",
    roleurl2: "",
    creativeurl1: "",
    creativeurl2: "",
    analytical_score: "",
    creativity_score: "",
    excel_score: "",
    reasoning_score: "",
    role_score: "",
    resume_score: "",
    total_score: "",
    tabSwitchingFrequency: "",
  });
  useEffect(() => {
    // console.log("candidateRef", candidateRef);
    // Check if the modal is open and candidateRef.current is not undefined
    if (isOpen && candidateRef.current) {
      // console.log(candidateRef.current);
      // data fetch from useRef
      let name = candidateRef.current.candidate.name;
      let email = candidateRef.current.email;
      let dob = candidateRef.current.candidate.dob;
      let mobno = candidateRef.current.candidate.mobno;
      let profile_photo = candidateRef.current.candidate.profile_photo;
      let videoResume = candidateRef.current.candidate.videoResume;
      let college = candidateRef.current.candidate.college;
      let collegedegree = candidateRef.current.candidate.collegedegree;
      let collegeyear = candidateRef.current.candidate.collegeyear;
      let collegecgpa = candidateRef.current.candidate.collegecgpa;
      let school = candidateRef.current.candidate.school;
      let schooldegree = candidateRef.current.candidate.schooldegree;
      let schoolyear = candidateRef.current.candidate.schoolyear;
      let schoolcgpa = candidateRef.current.candidate.schoolcgpa;
      let exe1 = candidateRef.current.candidate.exe1;
      let exedes1 = candidateRef.current.candidate.exedes1;
      let exe2 = candidateRef.current.candidate.exe2;
      let exedes2 = candidateRef.current.candidate.exedes2;
      let res = candidateRef.current.candidate.res;
      let resdes = candidateRef.current.candidate.resdes;
      let achievement1 = candidateRef.current.candidate.achievement1;
      let achievement1des = candidateRef.current.candidate.achievement1des;
      let achievement2 = candidateRef.current.candidate.achievement2;
      let achievement2des = candidateRef.current.candidate.achievement2des;
      let certificate = candidateRef.current.candidate.certificate;
      let certificatedes = candidateRef.current.candidate.certificatedes;
      let project = candidateRef.current.candidate.project;
      let projectdes = candidateRef.current.candidate.projectdes;
      let skills = candidateRef.current.candidate.skills;
      let roleQues1 = candidateRef.current.candidate.role_related.ques1;
      let roleQues2 = candidateRef.current.candidate.role_related.ques2;
      let creativityQues1 =
        candidateRef.current.candidate.creative_related.ques1;
      let creativityQues2 =
        candidateRef.current.candidate.creative_related.ques2;
      let roleUrl1 = candidateRef?.current?.candidate?.roleurl1 || null;
      let roleUrl2 = candidateRef?.current?.candidate?.roleurl2 || null;
      let creativeUrl1 = candidateRef?.current?.candidate?.creativeurl1 || null;
      let creativeUrl2 = candidateRef?.current?.candidate?.creativeurl2 || null;
      let analytical_score = candidateRef?.current?.candidate?.AnalyticalScore;
      let creativity_score = candidateRef?.current?.candidate?.CreativeScore;
      let excel_score = candidateRef?.current?.candidate?.ExcelScore;
      let reasoning_score = candidateRef?.current?.candidate?.ReasoningScore;
      let role_score = candidateRef?.current?.candidate?.RoleScore;
      let resume_score = candidateRef?.current?.candidate?.resume_score;
      let total_score = candidateRef?.current?.candidate?.totalScore;
      let tabSwitchingFrequency =
        candidateRef?.current?.candidate?.noOfTabSwitches;
      if (skills.length > 0) {
        setIsSkills(true);
      }
      // ////console.log("data = ", candidateRef.current);
      // set Form Data to Display
      setFormData({
        name: name,
        email: email,
        dob: dob,
        mobno: mobno,
        profile_photo: profile_photo,
        videoResume: videoResume,
        college: college,
        collegedegree: collegedegree,
        collegeyear: collegeyear,
        collegecgpa: collegecgpa,
        school: school,
        schooldegree: schooldegree,
        schoolyear: schoolyear,
        schoolcgpa: schoolcgpa,
        exe1: exe1,
        exedes1: exedes1,
        exe2: exe2,
        exedes2: exedes2,
        res: res,
        resdes: resdes,
        achievement1: achievement1,
        achievement1des: achievement1des,
        achievement2: achievement2,
        achievement2des: achievement2des,
        certificate: certificate,
        certificatedes: certificatedes,
        project: project,
        projectdes: projectdes,
        skills: skills,
        roleQues1: roleQues1,
        roleQues2: roleQues2,
        creativityQues1: creativityQues1,
        creativityQues2: creativityQues2,
        roleurl1: roleUrl1,
        roleurl2: roleUrl2,
        creativeurl1: creativeUrl1,
        creativeurl2: creativeUrl2,
        analytical_score: analytical_score,
        creativity_score: creativity_score,
        excel_score: excel_score,
        reasoning_score: reasoning_score,
        role_score: role_score,
        resume_score: resume_score,
        total_score: total_score,
        tabSwitchingFrequency: tabSwitchingFrequency,
      });
    }

    // console.log(formData.profile_photo);
  }, [isOpen, candidateRef]);

  const handleCloseModal = () => {
    setFormData({
      name: "",
      dob: "",
      mobno: "",
      email: "",
      profile_photo: "",
      videoResume: "",
      college: "",
      collegedegree: "",
      collegeyear: "",
      collegecgpa: "",
      school: "",
      schooldegree: "",
      schoolyear: "",
      schoolcgpa: "",
      exe1: "",
      exedes1: "",
      exe2: "",
      exedes2: "",
      res: "",
      resdes: "",
      achievement1: "",
      achievement1des: "",
      achievement2: "",
      achievement2des: "",
      certificate: "",
      certificatedes: "",
      project: "",
      projectdes: "",
      skills: [],
      roleQues1: "",
      roleQues2: "",
      creativityQues1: "",
      creativityQues2: "",
      roleurl1: "",
      roleurl2: "",
      creativeurl1: "",
      creativeurl2: "",
      analytical_score: "",
      creativity_score: "",
      excel_score: "",
      reasoning_score: "",
      role_score: "",
      resume_score: "",
      total_score: "",
      tabSwitchingFrequency: "",
    });
    closeModal();
  };

  const options = {
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: "20%",
          background: "transparent",
          image: undefined,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
    colors: [
      "#FD0100",
      "#F76915",
      "#b85042",
      "#2FA236",
      "#333ED4",
      "#011936ff",
      "#9B30FF",
    ],
    labels: [
      "Resume",
      "Excel Skills",
      "Subject Knowledge",
      "Creativity Score",
      "Analytical Skills",
      "Reasoning Skills",
      "Tab Switching Frequency",
    ],
    legend: {
      show: true,
      floating: true,
      fontSize: "16px",
      position: "left",
      offsetX: 20,
      offsetY: 0,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0,
      },
      formatter: function (seriesName, opts) {
        return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
      },
      itemMargin: {
        vertical: 3,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  const series = [
    formData.resume_score,
    formData.excel_score,
    formData.role_score,
    formData.creativity_score,
    formData.analytical_score,
    formData.reasoning_score,
    formData.tabSwitchingFrequency,
  ];

  return (
    <>
      <div
        className={`fixed inset-0 flex items-center justify-center z-50 ${
          isOpen ? "opacity-100 visible" : "opacity-0 invisible"
        } transition-opacity duration-300`}
      >
        <div className="absolute w-4/5 h-3/4 bg-white rounded-lg shadow-md overflow-y-auto">
          <div className="flex justify-end">
            <button
              onClick={handleCloseModal}
              className="m-2 text-gray-700 hover:text-red-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 0 1 1.414 0L8 6.586l2.293-2.293a1 1 0 1 1 1.414 1.414L9.414 8l2.293 2.293a1 1 0 0 1-1.414 1.414L8 9.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L6.586 8 4.293 5.707a1 1 0 0 1 0-1.414z"
                />
              </svg>
            </button>
          </div>
          {/* Modal content goes here */}
          <div className="h-full px-4">
            <div className={classes.container}>
              <div className={classes.box1}>
                <div
                  className="bg-gray-700/20 mt-4 w-[200px] h-[200px] md:w-[250px] md:h-[250px] rounded-full p-2" /*{classes.section1}*/
                >
                  <img
                    className="w-full h-full rounded-full object-cover" /*{classes.image}*/
                    src={formData.profile_photo ? formData.profile_photo : icon}
                    alt="profile"
                  />
                </div>
                <div className="mb-4 w-full px-3">
                  <h1 className="font-sans text-2xl font-bold w-full text-left">
                    {formData.name}
                  </h1>
                  <div className="">
                    <table className="table-auto w-full text-left">
                      <tbody>
                        <tr>
                          <td className="text-md font-medium text-black whitespace-nowrap print:text-sm">
                            Email :
                          </td>
                          <td className="px-1 text-black whitespace-pre-wrap print:text-sm">
                            {formData.email || "namanparlecha@gmail.com"}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-md font-medium text-black  whitespace-nowrap print:text-sm">
                            Number :
                          </td>
                          <td className="px-1 text-black print:text-sm">
                            {formData.mobno}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-md font-medium text-black whitespace-nowrap print:text-sm">
                            DOB :
                          </td>
                          <td className="px-1 text-black print:text-sm">
                            {formData.dob}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className={classes.box2}>
                <h1 className={classes.title}>Educational Details</h1>
                <hr className={classes.divide} />

                <div className={`${classes.section} flex gap-2`}>
                  <table className="flex-1 table-auto w-full text-left align-text-top">
                    <tbody className="w-full">
                      <tr className="w-full">
                        <td colSpan={2}>
                          <h3>College Details </h3>
                        </td>
                      </tr>
                      <tr className="w-full">
                        <td className="py-1 font-medium text-black whitespace-nowrap w-1/3 align-text-top">
                          Name :
                        </td>
                        <td className="py-1 text-black w-2/3">
                          {formData.college}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-1/3 h-full py-1 font-medium text-black whitespace-nowrap flex pt-2">
                          Branch :
                        </td>
                        <td className="w-2/3 py-1 text-black">
                          {formData.collegedegree}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-1/3 py-1 font-medium text-black whitespace-nowrap">
                          Year of Passing :
                        </td>
                        <td className="w-2/3 py-1 text-black">
                          {formData.collegeyear}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-1/3 py-1 font-medium text-black whitespace-nowrap">
                          CGPA :
                        </td>
                        <td className="w-2/3 py-1 text-black">
                          {formData.collegecgpa}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="flex-1 table-auto w-full text-left align-text-top">
                    <tbody className="w-full">
                      <tr className="w-full">
                        <td colSpan={2}>
                          <h3>School Details </h3>
                        </td>
                      </tr>
                      <tr>
                        <td className="py-1 font-medium text-black whitespace-nowrap w-1/3 align-text-top">
                          Name :
                        </td>
                        <td className="py-1 text-black w-2/3">
                          {formData.school}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-1/3 h-full py-1 font-medium text-black whitespace-nowrap flex pt-2">
                          Branch :
                        </td>
                        <td className="w-2/3 py-1 text-black">
                          {formData.schooldegree}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-1/3 py-1 font-medium text-black whitespace-nowrap">
                          Year of Passing :
                        </td>
                        <td className="w-2/3 py-1 text-black">
                          {formData.schoolyear}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-1/3 py-1 font-medium text-black whitespace-nowrap">
                          CGPA :
                        </td>
                        <td className="w-2/3 py-1 text-black">
                          {formData.schoolcgpa}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {(formData.exe1 || formData.exe2) && (
                  <>
                    <h1 className={classes.title}>Experience</h1>
                    <hr className={classes.divide} />

                    <div className={classes.section}>
                      {formData.exe1 && (
                        <div className={classes.part1}>
                          <div className={classes.expsection}>
                            <p>Name - {formData.exe1}</p>
                            <p className={classes.exedes1}>
                              {formData.exedes1}
                            </p>
                          </div>
                        </div>
                      )}
                      {formData.exe2 && (
                        <div className={classes.part2}>
                          <div className={classes.expsection}>
                            <p>Name - {formData.exe2}</p>
                            <p className={classes.exedes2}>
                              {formData.exedes1}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}

                {formData.res && (
                  <>
                    <h1 className={classes.title}>Responsibility</h1>
                    <hr className={classes.divide} />

                    <div className={classes.section}>
                      <div className={classes.part1}>
                        <p className={classes.subheading}>Responsibility 1</p>
                        <p>name - {formData.res}</p>
                        <p className={classes.des}>{formData.resdes}</p>
                      </div>
                    </div>
                  </>
                )}
                {(formData.achievement1 || formData.achievement2) && (
                  <>
                    <h1 className={classes.title}>Achivement</h1>
                    <hr className={classes.divide} />
                    <div className={classes.section}>
                      {formData.achievement1 && (
                        <div className={classes.part1}>
                          <p className={classes.subheading}>Achivement 1</p>
                          <p>name - {formData.achievement1}</p>
                          <p className={classes.des}>
                            {formData.achievement1des}
                          </p>
                        </div>
                      )}
                      {formData.achievement2 && (
                        <div className={classes.part2}>
                          <p className={classes.subheading}>Achivement 2</p>
                          <p>name - {formData.achievement2}</p>
                          <p className={classes.des}>
                            {formData.achievement2des}
                          </p>
                        </div>
                      )}
                    </div>
                  </>
                )}
                {formData.certificate && (
                  <>
                    <h1 className={classes.title}>Training & Certification</h1>
                    <hr className={classes.divide} />

                    <div className={classes.section}>
                      <div className={classes.part1}>
                        <p className={classes.subheading}>Certificate 1</p>
                        <p>name - {formData.certificate}</p>
                        <p className={classes.des}>{formData.certificatedes}</p>
                      </div>
                    </div>
                  </>
                )}

                {formData.project && (
                  <>
                    <h1 className={classes.title}>Project</h1>
                    <hr className={classes.divide} />
                    <div className={classes.section}>
                      <div className={classes.part1}>
                        <p className={classes.subheading}>Project 1</p>
                        <p>name - {formData.project}</p>
                        <p className={classes.des}>{formData.projectdes}</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={`${classes.radialBar} p-4 flex flex-col w-full`}>
              <DonutChart options={options} series={series} />

              <h2 className="w-full text-left text-lg">
                Candidate Score based on 7 parameters :{" "}
              </h2>
              <table className="w-full bg-white table-auto rounded-lg mb-4">
                <thead>
                  <tr>
                    <th className="border border-gray-300 p-2">Total Score</th>
                    <th className="border border-gray-300 p-2">Resume</th>
                    <th className="border border-gray-300 p-2">Excel</th>
                    <th className="border border-gray-300 p-2">
                      Subject Knowledge
                    </th>
                    <th className="border border-gray-300 p-2">Creativity</th>
                    <th className="border border-gray-300 p-2">Analytical</th>
                    <th className="border border-gray-300 p-2">Reasoning</th>
                    <th className="border border-gray-300 p-2">
                      Tab Switching Frequency
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {candidateRef?.current?.completedStages?.stage2 ? (
                      <>
                        <td className="border border-gray-300 p-2 text-center">
                          {formData.total_score}
                        </td>
                        <td className="border border-gray-300 p-2 text-center">
                          {formData.resume_score}
                        </td>
                        <td className="border border-gray-300 p-2 text-center">
                          {formData.excel_score}
                        </td>
                        <td className="border border-gray-300 p-2 text-center">
                          {formData.role_score}
                        </td>
                        <td className="border border-gray-300 p-2 text-center">
                          {formData.creativity_score}
                        </td>
                        <td className="border border-gray-300 p-2 text-center">
                          {formData.analytical_score}
                        </td>
                        <td className="border border-gray-300 p-2 text-center">
                          {formData.reasoning_score}
                        </td>
                        <td className="border border-gray-300 p-2 text-center">
                          {formData.tabSwitchingFrequency}
                        </td>
                      </>
                    ) : (
                      <td
                        className="py-2 px-4 text-center text-yellow-700"
                        colSpan="9"
                      >
                        Pending
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
              <h2 className="w-full text-left">Video Resume : </h2>
              <div className="mb-6">
                {formData.videoResume ? (
                  <div className="flex justify-center items-center px-4 mb-4">
                    <video
                      controls
                      className="w-full max-w-sm max-h-sm rounded-xl "
                    >
                      <source src={formData.videoResume} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <h2 className="w-full text-left">Role Related Questions : </h2>
              <div>
                {formData.roleQues1 && (
                  <div className="flex justify-center items-center px-4 flex-col h-fit">
                    <h4 className="w-full text-left font-semibold text-xl text-blue-700 mb-2">
                      Question 1 :{" "}
                      <span className="text-lg font-medium text-black">
                        {formData.roleQues1}
                      </span>
                    </h4>
                    {formData.roleurl1 ? (
                      <video
                        controls
                        className="w-full max-w-sm max-h-sm rounded-xl "
                      >
                        <source src={formData.roleurl1} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <p className="text-red-500 font-medium">
                        No answer found
                      </p>
                    )}
                  </div>
                )}
              </div>
              <div>
                {formData.roleQues2 && (
                  <div className="flex justify-center items-center p-4 flex-col h-fit">
                    <h4 className="w-full text-left font-semibold text-xl text-blue-700 mb-2">
                      Question 2 :{" "}
                      <span className="text-lg font-medium text-black">
                        {formData.roleQues2}
                      </span>
                    </h4>
                    {formData.roleurl2 ? (
                      <video
                        controls
                        className="w-full max-w-sm max-h-sm rounded-xl "
                      >
                        <source src={formData.roleurl2} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <p className="text-red-500 font-medium">
                        No answer found
                      </p>
                    )}
                  </div>
                )}
              </div>
              <h2 className="w-full text-left mt-6">
                Creativity Based Questions :{" "}
              </h2>
              <div>
                {formData.creativityQues1 && (
                  <div className="flex justify-center items-center px-4 flex-col h-fit">
                    <h4 className="w-full text-left font-semibold text-xl text-blue-700 mb-2">
                      Question 1 :{" "}
                      <span className="text-lg font-medium text-black">
                        {formData.creativityQues1}
                      </span>
                    </h4>
                    {formData.creativeurl1 ? (
                      <video
                        controls
                        className="w-full max-w-sm max-h-sm rounded-xl "
                      >
                        <source src={formData.creativeurl1} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <p className="text-red-500 font-medium">
                        No answer found
                      </p>
                    )}
                  </div>
                )}
              </div>
              <div>
                {formData.roleQues2 && (
                  <div className="flex justify-center items-center p-4 flex-col h-fit">
                    <h4 className="w-full text-left font-semibold text-xl text-blue-700 mb-2">
                      Question 2 :{" "}
                      <span className="text-lg font-medium text-black">
                        {formData.creativityQues2}
                      </span>
                    </h4>
                    {formData.creativeurl2 ? (
                      <video
                        controls
                        className="w-full max-w-sm max-h-sm rounded-xl "
                      >
                        <source src={formData.creativeurl2} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <p className="text-red-500 font-medium">
                        No answer found
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="py-4 h-fit">
              <Link
                className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-xl w-fit h-full mb-4"
                target="_blank"
                to={`/resume/${candidateRef.current?.candidate?.user}`}
              >
                Download QR based Resume
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
