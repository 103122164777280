import React, { useState, useRef, useEffect } from "react";
import Modal from "../../components/dashboard/Modal";
import {
  getAllJobsPosted,
  getAllCandidatesApplied,
  toggleShortlistedStatus,
} from "../../service/api";
import "../customCss/applicant.css";
import Pagination from "./Pagination";
import ResumeModal from "./ResumeModal";
import { MagnifyingGlass } from "react-loader-spinner";
// import PDFViewer from "./ResumeModal";

const Candidates = () => {
  const [shortlisted, setShortlisted] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [jobId, setJobId] = useState("");
  const [candidatesData, setCandidatesData] = useState([]);
  const selectedCandidateRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isResumeModalOpen, setIsResumeModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const token = getCookieAndRole();
    const getAllJobsPost = async (token) => {
      const getjobs = await getAllJobsPosted(token.token);
      // console.log(getjobs);
      setJobs(getjobs);
    };
    getAllJobsPost(token);
  }, []);

  useEffect(() => {
    setCandidatesData([]);

    const fetchCandidatesData = async () => {
      if (jobId) {
        const candidates = await getAllCandidatesApplied(jobId);
        // console.log(candidates);
        if (candidates.status === 200) {
          setCandidatesData(candidates.data);
          // console.log(candidates.data);
          // console.log(candidates.data[0].candidate.level);
        } else {
          setCandidatesData([]);
        }
      }
    };
    fetchCandidatesData();
  }, [jobId]);

  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };

  const toggleShortlist = async (index) => {
    const candidateId = candidatesData[index].candidate.user;
    const updatedShortlisted = [...shortlisted];
    updatedShortlisted[index] = !updatedShortlisted[index];
    setShortlisted(updatedShortlisted);

    try {
      // Assuming toggleShortlistStatus is a function that makes an API call to update shortlist status
      await toggleShortlistedStatus(jobId, candidateId);
      ////console.log(jobId);
      ////console.log(candidateId);
    } catch (error) {
      console.error("Error toggling shortlist status:", error);
      // Handle error, e.g., show a notification to the user
    }
  };

  const openModal = (candidate) => {
    selectedCandidateRef.current = candidate;
    setIsModalOpen(true);
  };

  const closeModal = () => {
    selectedCandidateRef.current = null;
    setIsModalOpen(false);
  };

  const [isLoadingPdf, setIsLoadingPdf] = useState(true);

  const openResumeModal = (candidate) => {
    selectedCandidateRef.current = candidate;
    setIsResumeModalOpen(true);
    if (!isResumeModalOpen) setIsLoadingPdf(true);
  };
  
  const handleLoad = () => {
    setIsLoadingPdf(false); // PDF is loaded, hide the loader
  };

  const closeResumeModal = () => {
    selectedCandidateRef.current = null;
    setIsResumeModalOpen(false);
  };

  const handleSelectChange = (event) => {
    setJobId(event.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

 
  return (
    <div className="bg-white border rounded-lg shadow-lg mb-3 p-5">
      {/* Top Section with Dropdown */}
      <div className="flex justify-between candidate-heading items-center">
        <div className="relative">
          <label
            className="text-2xl text-gray-700 font-semibold"
            htmlFor="jobRoles"
          >
            Job Roles
          </label>
          <select
            id="jobRoles"
            className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-3 pr-8 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            onChange={handleSelectChange}
            value={jobId} // Controlled component to set the selected value
          >
            <option className="text-blue-900" value="">
              Select a job
            </option>
            {jobs.map((job) => (
              <option key={job._id} value={job._id}>
                {job.jobTitle}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Candidate Table */}
      <div className="overflow-x-auto mt-5">
        <table className="min-w-full bg-white table-auto">
          <thead>
            <tr>
              <th className="border border-gray-300 p-2">SNo.</th>
              <th className="border border-gray-300 p-2">Email</th>
              <th className="border border-gray-300 p-2">Name</th>
              <th className="border border-gray-300 p-2">Phone No</th>
              <th className="border border-gray-300 p-2">Score</th>
              <th className="border border-gray-300 p-2">Role</th>
              <th className="border border-gray-300 p-2">Resume</th>
              <th className="border border-gray-300 p-2">PoW</th>
              <th className="border border-gray-300 p-2">Experience</th>
              <th className="border border-gray-300 p-2">Shortlist</th>
            </tr>
          </thead>
          <tbody>
            {candidatesData.length > 0 ? (
              candidatesData
                .slice((currentPage - 1) * 10, currentPage * 10)
                .map((candidate, index) => (
                  <tr key={index}>
                    <td className="border border-gray-300 p-2 text-center">
                      {(currentPage - 1) * 10 + index + 1}
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      {candidate.email}
                    </td>
                    <>
                      {candidate.completedStages.stage2 ? (
                        <>
                          <td className="border border-gray-300 p-2 text-center">
                            {candidate.candidate.name}
                          </td>
                          <td className="border border-gray-300 p-2 text-center">
                            {candidate.candidate.mobno}
                          </td>

                          <td className="border border-gray-300 p-2 text-center">
                            {candidate.candidate.totalScore}
                          </td>
                          <td className="border border-gray-300 p-2 text-center">
                            {candidate.candidate.role_related.role ===
                              "undefined"
                              ? "N/A"
                              : candidate.candidate.role_related.role}
                          </td>
                          <td className="border border-gray-300 p-2 text-center">
                            <button
                              onClick={() => openResumeModal(candidate.candidate)}
                              className="hover:underline cursor-pointer text-green-500"
                            >
                              View Resume
                            </button>
                            {isResumeModalOpen && (
                              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">

                                {isLoadingPdf && (
                                  <div className="magnifying-glass-wrapper absolute top-1/2 left-1/2 h-screen">
                                    <MagnifyingGlass
                                      visible={true}
                                      height="120"
                                      width="120"
                                      ariaLabel="MagnifyingGlass-loading"
                                      wrapperStyle={{}}
                                      wrapperClass="MagnifyingGlass-wrapper"
                                      glassColor="#c0efff"
                                      color="#2979FF"
                                    />
                                    <h3 className=" font-Kalam text-white">Loading Resume...</h3>
                                  </div>
                                )}
                                {/* Close Button */}
                                <button
                                  onClick={closeResumeModal}
                                  className="absolute top-5 right-5 text-white  rounded-full p-2 focus:outline-none">
                                  &#10005;
                                </button>
                                { }
                                {/* PDF Viewer */}
                                {console.log(selectedCandidateRef.current)}
                                <iframe
                                  src={`https://docs.google.com/gview?url=${selectedCandidateRef.current.Resume}&embedded=true`}
                                  title="PDF Viewer"
                                  className="w-screen h-screen p-10 mx-10 border-0 "
                                  onLoad={handleLoad}
                                />

                              </div>

                            )}
                          </td>
                          <td className="border border-gray-300 p-2 text-center">
                            <button
                              onClick={() => openModal(candidate)}
                              className="text-blue-500 hover:underline cursor-pointer"
                            >
                              View PoW
                            </button>
                            <Modal
                              isOpen={isModalOpen}
                              closeModal={closeModal}
                              candidateRef={selectedCandidateRef}
                            />
                          </td>
                          <td className="border border-gray-300 p-2 text-center">
                            {candidate.candidate?.level === "Fresher"
                              ? "0 - 2 Years"
                              : candidate.candidate?.level === "Experienced"
                                ? "2 - 5 Years"
                                : candidate.candidate?.level === "Professional "
                                  ? "5+ Years"
                                  : "N/A"}
                          </td>
                          <td className="border border-gray-300 p-2 text-center">
                            <button
                              className={`text-gray-500 hover:text-red-500 ${shortlisted[index] ? "text-red-500" : ""
                                }`}
                              onClick={() => toggleShortlist(index)}
                            >
                              {shortlisted[index] ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="red"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  className="w-6 h-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  className="w-6 h-6"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              )}
                            </button>
                          </td>
                        </>
                      ) : (
                        <td className="py-2 px-4 text-center text-yellow-700">
                          Pending
                        </td>
                      )}
                    </>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center py-4">
                  No Candidates Applied
                </td>
              </tr>
            )}
          </tbody>
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(candidatesData.length / 10)}
            perPage={10}
            handlePageChange={handlePageChange}
            results={candidatesData.length}
          />
        </table>
      </div>
    </div>
  );
};

export default Candidates;
