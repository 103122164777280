import React, { useState, useEffect, useRef } from "react";
import Instruct from "../../components/portal/Instruct";
import { useNavigate } from "react-router-dom";
import TestingNavbar from "../../components/NavBar/TestingNavbar";
import { toast } from "react-toastify";
import { fetchCreativequestions } from "../../service/api";

export default function InsCreative() {
  const DATA = {
    ins1: `Creative Idea Round`,
    ins2: "This round focuses on evaluating your ability to generate innovative ideas.",
    ins3: "Number of Questions",
    ins4: "There will be a total of 2 questions for this round.",
    ins5: "Time",
    ins6: "Candidates will have 2 minutes to respond to each question.",
    ins7: "Assessment Criteria",
    ins8: "Questions are designed to assess your creativity, problem-solving skills, and ability to think outside the box.",
  };

  const streamRef = useRef(null);
  // const [stream, setStream] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const questionsArray = useRef([]);
  const [recordedVideo, setRecordedVideo] = useState(null);
  const [seconds, setSeconds] = useState(30);
  const navigate = useNavigate();

  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };
  const getQuestionsFromOpenAI = async (data) => {
    try {
      let q5 = await fetchCreativequestions(data);
      questionsArray.current = [q5[0].ques, q5[1].ques];
      // console.log(questionsArray.current);
    } catch (error) {
      //console.log("Error getting questions from OpenAI", error.message);
      toast.error("Error Occured! Send Screenshot to Telegram!");
    }
  };

  const getCameraPermission = async () => {
    let token = getCookieAndRole();
    await getQuestionsFromOpenAI(token.token);
    setRecordedVideo(null);
    if ("MediaRecorder" in window) {
      try {
        const videoConstraints = {
          audio: false,
          video: true,
        };
        const audioConstraints = { audio: true };
        // create audio and video streams separately
        const audioStream = await navigator.mediaDevices.getUserMedia(
          audioConstraints
        );
        const videoStream = await navigator.mediaDevices.getUserMedia(
          videoConstraints
        );

        setButtonDisabled(false); // Enable the button when the streams are obtained
      } catch (err) {
        // console.error("Error accessing camera", err.message);
        toast.error("Check Camera Permission and Reset settings");
        return;
      }
    } else {
      // console.error("The MediaRecorder API is not supported in your browser.");
      toast.error("Check Camera Permission and Reset settings");

      // Display an error message on the UI or handle it as needed
      return;
    }
  };

  const handleStartTest = () => {
    // Pass questionsArray to the next page and navigate
    navigate("/idea", { state: { questionsArray: questionsArray.current } });
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    getCameraPermission();

    // Start the countdown timer
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // Cleanup the timer on unmount
    return () => clearInterval(timer);
  }, []);

  // Redirect if timer reaches 0
  useEffect(() => {
    if (seconds === 0) {
      getQuestionsFromOpenAI();
    }
  }, [seconds]);
  return (
    <div className="bg-gray-100 h-screen">
      <TestingNavbar
        showAvatar={true}
        handleLogout={() => navigate("/login")}
      />
      <div className="px-12">
        <Instruct instructions={DATA} />
        {/* Start Test Button */}
        <div className="mt-4 flex justify-center">
          {buttonDisabled ? (
            <div className="p-2 border-4 rounded-lg text-center border-black">
              <div className="text-4xl font-bold text-gray-800">
                {formatTime(seconds)}
              </div>
            </div>
          ) : (
            <button
              disabled={buttonDisabled}
              onClick={handleStartTest}
              className={`${
                buttonDisabled
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-700"
              } text-white font-bold py-2 px-4 rounded`}
            >
              Start Test
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
