import React, { useState, useRef, useEffect } from "react";
import AuthWrapper from "../../context/AuthWrapper";
import favicon from "../../images/favicon.png";
import { Link } from "react-router-dom";
import iconsmall from "../../images/iconsmall.png";
import { PresentationChartLineIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import EmployerComponent from "../../context/EmployerComponent";
import CandidateComponent from "../../context/CandidateComponent";

export const CandidateNavbar = () => {
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const closeMenuOnOutsideClick = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", closeMenuOnOutsideClick);

    return () => {
      window.removeEventListener("mousedown", closeMenuOnOutsideClick);
    };
  }, []);


  const handleEmpDashboard = () => {
    navigate("/emp-dashboard");
  };
  // //console.log(userData);
  return (
    <div className="relative flex items-center justify-between px-4 pt-8 lg:px-24 lg:py-4 bg-slate-100 shadow-lg">
      <Link
        to="/"
        aria-label="HiremeClub Logo"
        title="HiremeClub Logo"
        className="inline-flex items-center"
      >
        <img src={favicon} alt="Logo" className="h-16 w-16 mr-0" />
        <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 font-custom">
          Hireme<span className="text-blue-500">Club</span>
        </span>
      </Link>

      <div className="hidden lg:flex items-center space-x-4">
        <Link to="/hire">
          <button className="bg-white text-gray-700 hover:text-blue-600 hover:border-2 hover:border-blue-600 hover:border-solid font-medium tracking-wide transition-colors duration-200 rounded-lg px-6 py-2 shadow-sm">
            Hire Talent
          </button>
        </Link>

        <AuthWrapper
          candidateComponent={<CandidateComponent />}
          employerComponent={<EmployerComponent />}
          fallbackComponent={
            <Link to="/login">
              <button className="bg-blue-600 text-white font-medium tracking-wide transition-colors duration-200 rounded-lg px-6 py-2 shadow-sm hover:bg-blue-700">
                Login
              </button>
            </Link>
          }
        />
      </div>

      <div className="lg:hidden">
        <button
          aria-label="Open Menu"
          title="Open Menu"
          className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-blue-50 focus:bg-blue-50"
          onClick={() => setIsMenuOpen(true)}
        >
          <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
            />
            <path
              fill="currentColor"
              d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
            />
            <path
              fill="currentColor"
              d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
            />
          </svg>
        </button>

        {/*Mobile Menu - To display button */}
        {isMenuOpen && (
          <div className="absolute top-0 left-0 w-full z-10">
            <div className="p-5 bg-white border rounded shadow-sm">
              {/* Your mobile menu items */}
              <div className="flex items-center justify-between mb-4">
                <div>
                  <Link
                    to="/"
                    aria-label="HiremeClub logo"
                    title="HiremeClub logo"
                    className="inline-flex items-center"
                  >
                    <img
                      src={iconsmall}
                      alt="Logo"
                      className="h-16 w-16 mr-0"
                    />
                    <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 font-custom">
                      Hireme<span className="text-blue-500">Club</span>
                    </span>
                  </Link>
                </div>
                <div>
                  <button
                    aria-label="Close Menu"
                    title="Close Menu"
                    className="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <nav>
                <div className="flex flex-col space-y-4">
                  {/* Option Menu */}
                  <Link to="/hire">
                    <button className="bg-gray-600 text-white font-medium tracking-wide transition-colors duration-200 rounded-lg px-6 py-2 shadow-sm hover:bg-gray-700 w-full">
                      Hire Talent
                    </button>
                  </Link>

                  <AuthWrapper
                    candidateComponent={
                      <div className="flex icon items-center md:space-x-8 lg:flex w-full">
                        {/* Dashboard Menu */}
                        <div className="bg-gray-600 text-white font-medium tracking-wide transition-colors duration-200 rounded-lg px-6 py-2 shadow-sm hover:bg-gray-700 w-full">
                          <button
                            onClick={() => navigate("/dashboard")}
                            className="w-full"
                          >
                            <PresentationChartLineIcon className="h-6 w-6 mr-2 inline" />
                            Dashboard
                          </button>
                        </div>
                      </div>
                    }
                    employerComponent={
                      <div className="flex icon items-center md:space-x-8 lg:flex w-full">
                        {/* Dashboard Button */}
                        <div className="bg-gray-600 text-white font-medium tracking-wide transition-colors duration-200 rounded-lg px-6 py-2 shadow-sm hover:bg-gray-700 w-full">
                          <button
                            onClick={handleEmpDashboard}
                            className="w-full"
                          >
                            <PresentationChartLineIcon className="h-6 w-6 mr-2 inline" />
                            Dashboard
                          </button>
                        </div>
                      </div>
                    }
                    fallbackComponent={
                      <Link to="/login">
                        <button className="bg-blue-600 text-white font-medium tracking-wide transition-colors duration-200 rounded-lg px-6 py-2 shadow-sm hover:bg-blue-700 w-full">
                          Login
                        </button>
                      </Link>
                    }
                  />
                </div>
              </nav>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CandidateNavbar;
