import React, { useEffect } from "react";
import { MagnifyingGlass } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const MagnifyingGlassComponent = ({ destination }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(destination);
    }, 500);

    return () => {
      // Clear the timeout to prevent navigation if the component unmounts before 2 seconds
      clearTimeout(timeoutId);
    };
  }, [navigate, destination]);

  return (
    <div className="magnifying-glass-wrapper flex items-center justify-center h-screen">
      <MagnifyingGlass
        visible={true}
        height="160"
        width="160"
        ariaLabel="MagnifyingGlass-loading"
        wrapperStyle={{}}
        wrapperClass="MagnifyingGlass-wrapper"
        glassColor="#c0efff"
        color="#2979FF"
      />
    </div>
  );
};

export default MagnifyingGlassComponent;
