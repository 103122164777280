import React from "react";
import EmployerProfile from "../../components/EmployerProfile";

function Settings() {
  return (
    <>
      <EmployerProfile />
      {/* <ChangePassword  changePasswordApi={changePasswordEmployer}/> */}
    </>
  );
}

export default Settings;
