import React from "react";
import { Routes, Route } from "react-router-dom";
import Landing from "./pages/portal/Landing";
import EmployerLanding from "./pages/EmployerLanding";
import Stage3 from "./pages/Stage3";
import Stage2 from "./pages/Stage2";
import Stage1 from "./pages/Stage1";
import CreativeIdea from "./pages/creativeIdea";
import JobDetails from "./pages/JobDetails/JobDetails";
import Login from "./pages/Login";
import ResumeData from "./pages/ResumeData.js";
import Listing from "./pages/portal/Listing";
import ApplicantDashboard from "./pages/Dashboard/ApplicantDashboard.js";
import EmployerDashboard from "./pages/Dashboard/EmployerDashboard";
import EmployerLogin from "./pages/EmployerLogin";
import InstructionMcq from "./pages/Instructions/InstructionMCQ.js";
import InstructionVideo from "./pages/Instructions/InstructionVideo.js";
import Mcq from "./pages/Mcq";
import ErrorPage from "./pages/ErrorPage.js";
import PrivateRoute from "./context/PrivateRoute.js";
import EmpPrivateRoute from "./context/EmpPrivateRoute.js";
import { AuthProvider } from "./context/AuthProvider.js";
import { TabSwitchProvider } from "./context/TabSwitchContext.js";
import Applicant from "./components/dashboard/Applicant.js";
import Interview from "./components/dashboard/Interview.js";
import ComingSoon from "./components/ComingSoon.js";
import Wishlist from "./components/dashboard/Wishlist.js";
import ProfileForm from "./components/dashboard/Profile.js";
import Settings from "./components/dashboard/Settings.js";
import Candidates from "./components/dashboard/Candidates.js";
import PostJob from "./components/dashboard/PostJob.js";
import Employer from "./components/dashboard/Employer.js";
import InsCreative from "./pages/Instructions/InsCreative.js";
import Role from "./components/Role.js";
import Resume from "./pages/Resume.js";
import InStage3 from "./pages/Instructions/InsStage3.js";
import EmployerDetails from "./pages/EmployerDetails.js";
import ForgetPassword from "./pages/ForgetPassword.js";
import Instruction from "./pages/Instructions/Instruction.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Feedback from "./pages/Feedback.js";
import TestLink from "./components/dashboard/TestLink.js";
import AboutPage from "./pages/AboutUs.js";
import PrivacyPolicy from "./pages/PrivacyPolicy.js";
import TermsConditions from "./pages/TermsConditions.js";
import RefundPolicy from "./pages/RefundPolicy.js";
// here
function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <AuthProvider>
              <Landing />
            </AuthProvider>
          }
        />
        <Route
          path="/hire"
          element={
            <AuthProvider>
              <EmployerLanding />
            </AuthProvider>
          }
        />
        <Route
          path="/jobs"
          element={
            <AuthProvider>
              <Listing />
            </AuthProvider>
          }
        />
        <Route
          path="/jobs/details/:id"
          element={
            <AuthProvider>
              <JobDetails />
            </AuthProvider>
          }
        />

        <Route path="/update-password/:token" element={<ForgetPassword />} />
        <Route path="/resume/:token" element={<Resume />} />
        <Route path="/login" element={<Login />} />
        {/*These are all the Protected Routes which open after login */}
        <Route
          path=""
          element={
            <AuthProvider>
              <PrivateRoute />
            </AuthProvider>
          }
        >
          <Route path="/stage1" element={<Stage1 />} />
          <Route path="/resume-data" element={<ResumeData />} />
          <Route path="/dashboard//*" element={<ApplicantDashboard />}>
            <Route path="applicant" element={<Applicant />} />
            <Route path="interview" element={<ComingSoon />} />
            <Route path="inbox" element={<ComingSoon />} />
            <Route path="wishlist" element={<Wishlist />} />
            <Route path="profile" element={<ProfileForm />} />
          </Route>
        </Route>

        {/*Tab Switch Detection - Testing Portal */}
        <Route
          path=""
          element={
            <AuthProvider>
              <TabSwitchProvider>
                <PrivateRoute />
              </TabSwitchProvider>
            </AuthProvider>
          }
        >
          <Route path="/instruction" element={<Instruction />} />
          <Route path="/role" element={<Role />} />
          <Route path="/instruction-video" element={<InstructionVideo />} />
          <Route path="/instruction-mcq" element={<InstructionMcq />} />
          <Route path="/mcq" element={<Mcq />} />
          <Route path="/insidea" element={<InsCreative />} />
          <Route path="/stage2" element={<Stage2 />} />
          <Route path="/insstage3" element={<InStage3 />} />
          <Route path="/stage3" element={<Stage3 />} />
          <Route path="/idea" element={<CreativeIdea />} />
          <Route path="/feedback" element={<Feedback />} />
        </Route>

        <Route path="/emp-login" element={<EmployerLogin />} />
        {/*All protected Routes to Login into Employer side */}
        <Route
          path=""
          element={
            <AuthProvider>
              <EmpPrivateRoute />
            </AuthProvider>
          }
        >
          <Route path="/empDetails" element={<EmployerDetails />} />
          <Route path="/emp-dashboard//*" element={<EmployerDashboard />}>
            <Route path="" element={<Employer />} />
            <Route path="postjob" element={<PostJob />} />
            <Route path="test" element={<TestLink />} />
            <Route path="interview" element={<Interview />} />
            <Route path="candidates" element={<Candidates />} />
            <Route path="profile" element={<Settings />} />
          </Route>
        </Route>
        <Route path="*" element={<ErrorPage />} />
        <Route path="/about-us" element={<AboutPage />} />
        <Route path="/policy" element={<PrivacyPolicy />} />
        <Route path="/tnc" element={<TermsConditions />} />
        <Route path="/refunds" element={<RefundPolicy />} />
        {/* Add more routes as needed */}
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />{" "}
    </>
  );
}

export default App;
